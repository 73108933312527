import React from "react";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@material-ui/core/Typography";
import TimelineIcon from "@mui/icons-material/Timeline";
import { useSelector, connect,useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { downloadFilesAdmin } from "../../../utils/utils";
import moment from "moment";
import {
  downloaddashboardrbhsmacsvdata,
} from "../../../redux/actions";


const SalesManagementOverviewCard = ({ classes, roleId }) => {
  const history = useHistory()
  const select = useSelector((state) => state);
  const dispatch = useDispatch();
  const data = select.commonReducer.activityTypeDetailCount;
  const dataForRBHSMActivities = select.commonReducer.activityTypeDetailCountRBH;
  const user = select.userReducer.profileInfo;
  const { viewForStatus } = useSelector(state => state.commonReducer)
  const smaList = () => {
    if (roleId == "NH" || roleId == "ZBH" || roleId == "RBH") {
      return false
    } else {
      history.push("/app/activity/smaActivities")
    }
  }

  const csvExp = async () => {
      try {
       // props.activateLoader();
        let downloadRes;
        const data = {"Date":moment(new Date()).format("YYYY-MM-DD")}
      // let reqJSon= {"CreateStartDate":null,"CreateEndDate":null,"MeetingStartDate":null,"MeetingEndDate":null,"CurrentStatus":"1,2,3,4","ActivityName":null,"ActivityType":"75,76,77,78,79,80","UserId":1762292,"BranchId":null,"CampaignName":null,"EmployeeCode":null}
        downloadRes = await dispatch(downloaddashboardrbhsmacsvdata(data)); 
       
        downloadFilesAdmin(downloadRes,"Sales management dashboard MIS");
       // props.deActivateLoader();
      }
      catch (err) {
       // props.deActivateLoader();
      }
    }

  return (
    <>

      {user.channelid != 79 && user.roleId === "ABH" || (viewForStatus !== "SELF" && (user.roleId === "RBH" || user.roleId === "ZBH" || user.roleId === "NH")) ? (
        <Paper className={classes.paper2} sx={{ mb: 2 }}>
          <Grid className="activityOverviewSec" container spacing={2}>
            <Grid item xs={12} md={12}>
              <Grid container>
                <Grid item xs={10}>
                  <Typography variant="h5" gutterBottom>
                    ABH Sales Management Activities
                  </Typography>
                </Grid>
                <Grid item xs={2} textAlign="right" onClick={smaList}>
                  <p className="viewAll">View All</p>
                </Grid>
              </Grid>
              {data && data.map((val) => {
                return (
                  <Grid className="salesManagementDiv"
                    container>
                    <Grid item xs={2} textAlign="center">
                      <TimelineIcon color="primary" />
                    </Grid>
                    <Grid item xs={7}>
                      <h5 style={{ color: "black" }}>{val.ActivityName}</h5>
                    </Grid>

                    <Grid item xs={3} className="totalDiv" textAlign="right">
                      <h6>{`${val.ActivityPlanned === null ? 0 : val.ActivityPlanned
                        }/${val.TotalActivity}`}</h6>
                    </Grid>
                    <Grid item xs={6} textAlign="center">
                      <h6 className="daysDiv">{val.DayLeft} days left</h6>
                    </Grid>

                    <Grid item xs={6} textAlign="right">
                      <h6 className="daysDiv">{val.ActivityPercentage}%</h6>
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Paper>
      ) : (
        ""
      )}
      {/* //RBH Sales management activity */}
       {(user.channelid == 1 && user.roleId === "RBH" && viewForStatus == "SELF") || 
       (user.channelid == 1 && user.roleId === "ZBH" && viewForStatus !== "SELF") ? (
        <Paper className={classes.paper2} sx={{ mb: 2 }}>
          <Grid className="activityOverviewSec" container spacing={2}>
            <Grid item xs={12} md={12}>
              <Grid container>
                <Grid item xs={10}>
                  <Typography variant="h5" gutterBottom>
                  RBH Sales Management Dashboard
                  </Typography>
                </Grid>
                <Grid item xs={2} textAlign="right" onClick={smaList}>
                  <p className="viewAll">View All</p>
                </Grid> 
                </Grid>
              {dataForRBHSMActivities && dataForRBHSMActivities.map((val) => {
                return (
                  <Grid className="salesManagementDiv"
                    container>
                    <Grid item xs={2} textAlign="center">
                      <TimelineIcon color="primary" />
                    </Grid>
                    <Grid item xs={7}>
                      <h5 style={{ color: "black" }}>{val.ActivityName}</h5>
                    </Grid>

                    <Grid item xs={3} className="totalDiv" textAlign="right">
                      <h6>{`${val.Target === null ? 0 : val.Target}`}</h6>
                     
                    </Grid>
                    <Grid item xs={6} className="totalDiv" textAlign="left">
                      <h6>{val.Frequency}</h6>
                     
                    </Grid>
                    <Grid item xs={3} textAlign="center">
                      <h6 className="daysDiv">{val.ActivityCompleted}</h6>
                      </Grid>

                    <Grid item xs={3} textAlign="right">
                      <h6 className="daysDiv">{`${val.ActivityPercentage === null ? 0 : val.ActivityPercentage}`}%</h6>
                    </Grid>
                  </Grid>
                );
              })}
              {/* onClick={csvExp} */}
              <Grid item xs={12} textAlign="right" onClick={csvExp}>
                  <p className="viewAll" >Download Report</p>
                </Grid>
            </Grid>
          </Grid>
        </Paper>
      ) : (
        ""
      )}
      {
        (viewForStatus !== "ABH" && viewForStatus !== "RBH" && viewForStatus !== "ZBH" && viewForStatus !== "NH" && viewForStatus !== "SELF" ) ? (
      <Paper className={classes.paper2} sx={{ mb: 2 }}>
        <Grid className="activityOverviewSec" container spacing={2}>
          <Grid item xs={12} md={12}>
            <Grid container>
              <Grid item xs={10}>
                <Typography variant="h5" gutterBottom>
                  RBH Sales Management Activities
                </Typography>
              </Grid>
              <Grid item xs={2} textAlign="right" onClick={smaList}>
                <p className="viewAll">View All</p>
              </Grid>
            </Grid>
            {/* { data && data.map((val) => {
                  return (
                    <Grid className="salesManagementDiv"
                      container>
                      <Grid item xs={2} textAlign="center">
                        <TimelineIcon color="primary" />
                      </Grid>
                      <Grid item xs={7}>
                        <h5 style={{ color: "black" }}>{val.ActivityName}</h5>
                      </Grid>
  
                      <Grid item xs={3} className="totalDiv" textAlign="right">
                        <h6>{`${
                          val.ActivityPlanned === null ? 0 : val.ActivityPlanned
                        }/${val.TotalActivity}`}</h6>
                      </Grid>
                      <Grid item xs={6} textAlign="center">
                        <h6 className="daysDiv">{val.DayLeft} days left</h6>
                      </Grid>
  
                      <Grid item xs={6} textAlign="right">
                        <h6 className="daysDiv">{val.ActivityPercentage}%</h6>
                      </Grid>
                    </Grid>
                  );
                })} */}
            <Grid className="salesManagementDiv"
              container>
              <Grid item xs={2} textAlign="center">
                <TimelineIcon color="primary" />
              </Grid>
              <Grid item xs={7}>
                <h5 style={{ color: "black" }}>Schedule Structured Review (ABH’s)</h5>
              </Grid>

              <Grid item xs={3} className="totalDiv" textAlign="right">
                <h6>{`${0}/${0}`}</h6>
              </Grid>
              <Grid item xs={6} textAlign="center">
                <h6 className="daysDiv">{0} days left</h6>
              </Grid>

              <Grid item xs={6} textAlign="right">
                <h6 className="daysDiv">{0}%</h6>
              </Grid>
            </Grid>
            <Grid className="salesManagementDiv"
              container>
              <Grid item xs={2} textAlign="center">
                <TimelineIcon color="primary" />
              </Grid>
              <Grid item xs={7}>
                <h5 style={{ color: "black" }}> Meeting with top 10 BDM’s </h5>
              </Grid>

              <Grid item xs={3} className="totalDiv" textAlign="right">
                <h6>{`${0}/${0}`}</h6>
              </Grid>
              <Grid item xs={6} textAlign="center">
                <h6 className="daysDiv">{0} days left</h6>
              </Grid>

              <Grid item xs={6} textAlign="right">
                <h6 className="daysDiv">{0}%</h6>
              </Grid>
            </Grid>
            <Grid className="salesManagementDiv"
              container>
              <Grid item xs={2} textAlign="center">
                <TimelineIcon color="primary" />
              </Grid>
              <Grid item xs={7}>
                <h5 style={{ color: "black" }}> One to one Review with bottom 10 BDM’s </h5>
              </Grid>

              <Grid item xs={3} className="totalDiv" textAlign="right">
                <h6>{`${0}/${0}`}</h6>
              </Grid>
              <Grid item xs={6} textAlign="center">
                <h6 className="daysDiv">{0} days left</h6>
              </Grid>

              <Grid item xs={6} textAlign="right">
                <h6 className="daysDiv">{0}%</h6>
              </Grid>
            </Grid>
            <Grid className="salesManagementDiv"
              container>
              <Grid item xs={2} textAlign="center">
                <TimelineIcon color="primary" />
              </Grid>
              <Grid item xs={7}>
                <h5 style={{ color: "black" }}>  Region Visit with ABH </h5>
              </Grid>

              <Grid item xs={3} className="totalDiv" textAlign="right">
                <h6>{`${0}/${0}`}</h6>
              </Grid>
              <Grid item xs={6} textAlign="center">
                <h6 className="daysDiv">{0} days left</h6>
              </Grid>

              <Grid item xs={6} textAlign="right">
                <h6 className="daysDiv">{0}%</h6>
              </Grid>
            </Grid>
            <Grid className="salesManagementDiv"
              container>
              <Grid item xs={2} textAlign="center">
                <TimelineIcon color="primary" />
              </Grid>
              <Grid item xs={7}>
                <h5 style={{ color: "black" }}>  Branch Visit with BDM & ABH </h5>
              </Grid>

              <Grid item xs={3} className="totalDiv" textAlign="right">
                <h6>{`${0}/${0}`}</h6>
              </Grid>
              <Grid item xs={6} textAlign="center">
                <h6 className="daysDiv">{0} days left</h6>
              </Grid>

              <Grid item xs={6} textAlign="right">
                <h6 className="daysDiv">{0}%</h6>
              </Grid>
            </Grid>
            <Grid className="salesManagementDiv"
              container>
              <Grid item xs={2} textAlign="center">
                <TimelineIcon color="primary" />
              </Grid>
              <Grid item xs={7}>
                <h5 style={{ color: "black" }}> Joint calls with customer </h5>
              </Grid>

              <Grid item xs={3} className="totalDiv" textAlign="right">
                <h6>{`${0}/${0}`}</h6>
              </Grid>
              <Grid item xs={6} textAlign="center">
                <h6 className="daysDiv">{0} days left</h6>
              </Grid>

              <Grid item xs={6} textAlign="right">
                <h6 className="daysDiv">{0}%</h6>
              </Grid>
            </Grid>

          </Grid>
        </Grid>
      </Paper>
      ) : (
      ""
      )
      }
    </>
  );
};
const mapStateToProps = (state) => ({
  masterDataState: state.commonReducer,
});
export default withTranslation("translation", { withRef: true })(
  connect(mapStateToProps, {
    downloaddashboardrbhsmacsvdata
  })(SalesManagementOverviewCard)
);


