/**
 * @author Kiran Mohare
 * @email kiran.mohare@fulcrumdigital.com
 * @create date 2022-03-17 11:27:18
 * @modify date 2022-03-17 11:27:18
 * @desc [description]
 */

import DashboardIcon from '@mui/icons-material/Dashboard';
import PersonIcon from '@mui/icons-material/Person';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import SettingsIcon from '@mui/icons-material/Settings';
import LocationOnIcon from '@mui/icons-material/LocationOn';
//import SummarizeIcon from '@mui/icons-material/Summarize';

import React from 'react';
import * as RiIcons from 'react-icons/ri';

export const menuList = [
  {
    id: 1,
    title: "Dashboard",
    route: "/app/home",
    active: true,
    icon: <DashboardIcon />,
  },
  {
    id: 2,
    title: "Activities",
    route: "/app/activity/all",
    active: false,
    icon: <ContentPasteIcon />,
  },
  {
    id: 15,
    title: "Campaign Activities",
    route: "/app/campaignactivity/all",
    active: false,
    icon: <ContentPasteIcon />,
  },
  {
    id: 9,
    title: "Sales Management Activities",
    route: "/app/activity/smaActivities",
    active: false,
    icon: <ContentPasteIcon />,
  },
  {
    id: 3,
    title: "Leads",
    route: "/app/lead/all",
    active: false,
    icon: <PersonIcon />,
  },
  {
    id: 13,
    title: "Business Leads",
    route: "/app/businesslead/all",
    active: false,
    icon: <PersonIcon />,
  },
  {
    id: 14,
    title: "Recruitment Leads",
    route: "/app/recruitmentlead/all",
    active: false,
    icon: <PersonIcon />,
  },
  {
    id: 4,
    title: "Persistency Leads",
    route: "/app/persistencylead/all",
    active: false,
    icon: <PersonIcon />,
  },
  {
    id: 5,
    title: "Simplify",
    route: "/app/simplify",
    active: false,
    icon: <AccountBalanceWalletIcon />,
  },
  {
    id: 6,
    title: "Campaign Leads",
    route: "/app/campaignlead/all",
    active: false,
    icon: <PersonIcon />,
  },
  {
    id: 7,
    title: "Calendar",
    route: "/app/calendar",
    active: false,
    icon: <CalendarMonthIcon />,
  },
  {
    id: 31,
    title: "Geo Tracking",
    route: "/app/geo-tracking",
    active: true,
    icon: <LocationOnIcon />,
  },

  {
    title: "Reports",
    route: "#",
    id: 17,
    icon: <PersonIcon />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [
      {
        id: 25,
        title: "Business Leads",
        route: "/app/report/businesslead/all",
        icon: <PersonIcon />,
      },
      {
        id: 26,
        title: "Persistency Leads",
        route: "/app/report/persistencylead/all",
        active: false,
        icon: <PersonIcon />,
      },
      {
        id: 27,
        title: "Campaign Leads",
        route: "/app/report/campaignlead/all",
        active: false,
        icon: <PersonIcon />,
      },
      {
        id: 28,
        title: "Activities",
        route: "/app/report/activity/all",
        active: false,
        icon: <ContentPasteIcon />,
      },
      {
        id: 29,
        title: "ABH Sales Management Activities",
        route: "/app/report/smaActivities/all",
        active: false,
        icon: <ContentPasteIcon />,
      },
    ],
  },
  {
    title: "Reports",
    route: "#",
    id: 49,
    icon: <PersonIcon />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [
      {
        id: 25,
        title: "Business Leads",
        route: "/app/report/businesslead/all",
        icon: <PersonIcon />,
      },
      {
        id: 26,
        title: "Persistency Leads",
        route: "/app/report/persistencylead/all",
        active: false,
        icon: <PersonIcon />,
      },
      {
        id: 27,
        title: "Campaign Leads",
        route: "/app/report/campaignlead/all",
        active: false,
        icon: <PersonIcon />,
      },
      {
        id: 28,
        title: "Activities",
        route: "/app/report/activity/all",
        active: false,
        icon: <ContentPasteIcon />,
      },
      {
        id: 29,
        title: "ABH Sales Management Activities",
        route: "/app/report/smaActivities/all",
        active: false,
        icon: <ContentPasteIcon />,
      },
      {
        id: 49,
        title: "RBH Sales Management Activities",
        route: "/app/report/rbhsmaActivities/all",
        active: false,
        icon: <ContentPasteIcon />,
      },
    ],
  },
  {
    title: "Reports",
    route: "#",
    id: 18,
    icon: <PersonIcon />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [
      {
        id: 25,
        title: "Business Leads",
        route: "/app/report/businesslead/all",
        icon: <PersonIcon />,
      },
      {
        id: 26,
        title: "Persistency Leads",
        route: "/app/report/persistencylead/all",
        active: false,
        icon: <PersonIcon />,
      },
      {
        id: 27,
        title: "Campaign Leads",
        route: "/app/report/campaignlead/all",
        active: false,
        icon: <PersonIcon />,
      },
      {
        id: 28,
        title: "Activities",
        route: "/app/report/activity/all",
        active: false,
        icon: <ContentPasteIcon />,
      },
      {
        id: 14,
        title: 'Recruitment Leads',
        route: '/app/report/recruitmentlead/all',
        active: false,
        icon: (<PersonIcon />),
      }
    ],
  },
  {
    title: "Reports",
    route: "#",
    id: 19,
    icon: <PersonIcon />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [
      {
        id: 26,
        title: "Persistency Leads",
        route: "/app/report/persistencylead/all",
        active: false,
        icon: <PersonIcon />,
      },
      {
        id: 28,
        title: "Activities",
        route: "/app/report/activity/all",
        active: false,
        icon: <ContentPasteIcon />,
      },
      {
        id: 16,
        title: 'Campaign Activities',
        route: '/app/report/campaignactivity/all',
        active: false,
        icon: (<ContentPasteIcon />),
      },
    ],
  },
  {
    title: 'Reports',
    route: '#',
    id: 20,
    icon: <PersonIcon />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [

      {
        id: 29,
        title: 'Leads',
        route: '/app/report/businesslead/all',
        //  active: false,
        icon: (<PersonIcon />),
      },

      {
        id: 30,
        title: 'Activities',
        route: '/app/report/activity/all',
        active: false,
        icon: (<ContentPasteIcon />),
      },
      {
        id: 36,
        title: "Branch Partner and Category-wise",
        route: "/app/report/broca/branchpartner/activity/all",
        active: false,
        icon: <ContentPasteIcon />,
      },
      {
        id: 37,
        title: "Must Do Activity Summary",
        route: "/app/report/broca/mustdo/activity/all",
        active: false,
        icon: <ContentPasteIcon />,
      },
      {
        id: 38,
        title: "Lead Generation Activity Summary",
        route: "/app/report/broca/leadgeneration/activity/all",
        active: false,
        icon: <ContentPasteIcon />,
      },
      {
        id: 39,
        title: "Branch Foundation Day wise activity summary",
        route: "/app/report/broca/bfdwise/activity/all",
        active: false,
        icon: <ContentPasteIcon />,
      },
      {
        id: 40,
        title: "Partner Engagement Activity Summary",
        route: "/app/report/broca/partnerengagement/activity/all",
        active: false,
        icon: <ContentPasteIcon />,
      },
      {
        id: 41,
        title: "Business Development Activity",
        route: "/app/report/broca/businessdevelopment/activity/all",
        active: false,
        icon: <ContentPasteIcon />,
      },
    ]
  },
  {
    title: "Reports",
    route: "#",
    id: 21,
    icon: <PersonIcon />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [
      {
        id: 31,
        title: "Business Leads",
        route: "/app/report/businesslead/all",
        icon: <PersonIcon />,
      },
      // {
      //   id: 32,
      //   title: "Campaign Leads",
      //   route: "/app/report/campaignlead/all",
      //   active: false,
      //   icon: <PersonIcon />,
      // },
      {
        id: 33,
        title: "Activities",
        route: "/app/report/activity/all",
        active: false,
        icon: <ContentPasteIcon />,
      },
    ],
  },
  {
    title: "Reports",
    route: "#",
    id: 22,
    icon: <PersonIcon />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [
      {
        id: 34,
        title: "Business Leads",
        route: "/app/report/businesslead/all",
        icon: <PersonIcon />,
      },
      {
        id: 35,
        title: "Activities",
        route: "/app/report/activity/all",
        active: false,
        icon: <ContentPasteIcon />,
      },
    ],
  },
  {
    title: "Reports",
    route: "#",
    id: 23,
    icon: <PersonIcon />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [
      {
        id: 36,
        title: "Activities",
        route: "/app/report/activity/all",
        active: false,
        icon: <ContentPasteIcon />,
      },
    ],
  },
  {
    id: 8,
    title: "Profile Settings",
    route: "/app/profilesetting",
    active: false,
    icon: <SettingsIcon />,
  },
];

export const menuListAdmin = [
  {
    id: 1,
    title: 'Dashboard',
    route: '/app/home/admin',
    active: true,
    icon: (<DashboardIcon />),
  },
  {
    title: 'Banca',
    route: '#',
    icon: <PersonIcon />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [

      {

        id: 2,
        title: 'Persistency Leads',
        route: '/app/admin/persistencylead/banca/all',
        //  active: false,
        icon: (<PersonIcon />),


      },
      {
        id: 3,
        title: 'Campaign Leads',
        route: '/app/admin/campaignlead/banca/all',
        active: false,
        icon: (<PersonIcon />),
      },
      {
        id: 4,
        title: 'Activities',
        route: '/app/admin/campaignactivity/banca/all',
        active: false,
        icon: (<ContentPasteIcon />),
      },
      {
        id: 17,
        title: 'ABH Sales Management Activities',
        route: '/app/admin/smaActivities/banca/all',
        active: false,
        icon: (<ContentPasteIcon />),
      },
      {
        id: 39,
        title: 'RBH Sales Management Activities',
        route: '/app/admin/smaActivities/rbhbanca/all',
        active: false,
        icon: (<ContentPasteIcon />),
      },
      {
        id: 15,
        title: 'Business Leads',
        route: '/app/admin/businesslead/banca/all',
        active: false,
        icon: (<PersonIcon />),
      },
    ]
  },
  {
    title: 'CSC',
    route: '#',
    icon: <PersonIcon />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [

      {
        id: 11,
        title: 'Persistency Leads',
        route: '/app/admin/persistencylead/csc/all',
        //  active: false,
        icon: (<PersonIcon />),
      },

      {
        id: 6,
        title: 'Activities',
        route: '/app/admin/activity/csc/all',
        active: false,
        icon: (<ContentPasteIcon />),
      },
      {
        id: 16,
        title: 'Campaign Activities',
        route: '/app/admin/campaignactivity/csc/all',
        active: false,
        icon: (<ContentPasteIcon />),
      },
    ]
  },
  {
    title: 'Agency',
    route: '#',
    icon: <PersonIcon />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [
      {
        id: 8,
        title: 'Persistency Leads',
        route: '/app/admin/persistencylead/agency/all',
        active: false,
        icon: (<PersonIcon />),
      },
      {
        id: 9,
        title: 'Campaign Leads',
        route: '/app/admin/campaignlead/agency/all',
        active: false,
        icon: (<PersonIcon />),
      }
      ,
      {
        id: 12,
        title: 'Activities',
        route: '/app/admin/campaignactivity/agency/all',
        active: false,
        icon: (<ContentPasteIcon />),
      },
      {
        id: 13,
        title: 'Business Leads',
        route: '/app/admin/businesslead/agency/all',
        active: false,
        icon: (<PersonIcon />),
      },
      {
        id: 14,
        title: 'Recruitment Leads',
        route: '/app/admin/recruitmentlead/agency/all',
        active: false,
        icon: (<PersonIcon />),
      }

    ]
  },
  {
    title: 'Broca',
    route: '#',
    icon: <PersonIcon />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [

      {
        id: 15,
        title: 'Business Leads',
        route: '/app/admin/businesslead/broca/all',
        //  active: false,
        icon: (<PersonIcon />),
      },

      {
        id: 16,
        title: 'Activities',
        route: '/app/admin/campaignactivity/broca/all',
        active: false,
        icon: (<ContentPasteIcon />),
      },
      {
        id: 36,
        title: "Branch Partner and Category-wise",
        route: "/app/admin/broca/branchpartner/activity/all",
        active: false,
        icon: <ContentPasteIcon />,
      },
      {
        id: 37,
        title: "Must Do Activity Summary",
        route: "/app/admin/broca/mustdo/activity/all",
        active: false,
        icon: <ContentPasteIcon />,
      },
      {
        id: 38,
        title: "Lead Generation Activity Summary",
        route: "/app/admin/broca/leadgeneration/activity/all",
        active: false,
        icon: <ContentPasteIcon />,
      },
      {
        id: 39,
        title: "Branch Foundation Day wise activity summary",
        route: "/app/admin/broca/bfdwise/activity/all",
        active: false,
        icon: <ContentPasteIcon />,
      },
      {
        id: 40,
        title: "Partner Engagement Activity Summary",
        route: "/app/admin/broca/partnerengagement/activity/all",
        active: false,
        icon: <ContentPasteIcon />,
      },
      {
        id: 41,
        title: "Business Development Activity",
        route: "/app/admin/broca/businessdevelopment/activity/all",
        active: false,
        icon: <ContentPasteIcon />,
      },

    ]
  }, 
  {
    title: 'GCL',
    route: '#',
    icon: <PersonIcon />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [

       {
        id: 42,
       title: 'Business Leads',
       route:'/app/admin/businesslead/gcl/all',
      //  active: false,
       icon: (<PersonIcon />),
     },
      

      {
        id: 43,
        title: 'Activities',
        route: '/app/admin/campaignactivity/gcl/all',
        active: false,
        icon: (<ContentPasteIcon />),
      },
    ]
  },

]
export const dashboardSettingMenuList = [
  {
    id: 1,
    title: 'Calendar',
    isChecked: true,
  },
  {
    id: 2,
    title: 'Activity Overview',
    isChecked: true,
  },
  {
    id: 3,
    title: 'Leads Overview',
    isChecked: true,
  },
  {
    id: 4,
    title: 'Campaign Leads Overview',
    isChecked: true,
  },
  {
    id: 5,
    title: 'Persistency Leads Overview',
    isChecked: true,
  },
  {
    id: 6,
    title: 'Account Overview',
    isChecked: true,
  },
];