import React, { useEffect, useState } from "react";
import {
  getActivityListByIdForTable,
  getActivityForm,
  getActivityListByIdForDraftTable,
  getActivityListByIdForBacklogTable,
  deleteActivityListByIdForDraftTable,
} from "../../redux/actions/updateActivityAction";
import { useHistory, useLocation } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { connect, useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Card from "./card";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Grid from "@material-ui/core/Grid";
import moment from "moment";
import {
  activateLoader,
  deActivateLoader,
  getSubordinateList,
  getActivityCsvFile
} from "../../redux/actions/common";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import DynamicForm from "f1-dynamic-form-react";
import { filterFormData, BacklogfilterFormData, LEAD_DATE } from './filterFormJson';
import CloseIcon from '@mui/icons-material/Close';
import { ROLE_BDM, ROLE_ABH, ROLE_FPM, USER_TYPE, AGENCY_ACTIVITY, BANCA_FPH_ACTIVITY, BANCA_BDM_ACTIVITY, BROCA_COMMON_ACTIVITY_TYPES_ID, MSME_BDM_ACTIVITY_FILTER, GCL_BDM_ACTIVITY_FILTER ,BANCA_RBH_SMA_ACTIVITY_NEW,CORPORATE_ACTIVITY_TYPES_ID} from '../../utils/config'

import { getDropdownDataBranch } from '../../utils/utils'

import "./tableNcard.scss";
import ActivityDraftListing from "../draftListing/activityDraftListing";
import {
  getAllActivityOffline,
  getFilterActivities,
} from "../../offlineManager/activityOfflineManager";
import BacklogActivity from "./backlogActivity";
import { activateErrorMessageBar, setFilterBy } from "../../redux/actions";
import useOnlineStatus from "../../hooks/useOnlineStatus";

const meetingDateFormate = "YYYY-MM-DD";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  drawerMobile: {
    display: "none",
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  desktopFilter: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

const backlogActivityStatusId = "1,2,3,4,5,9,10,11,12,13,17,18,19,20,21,24,25,26,27,28,29,30,33,34,35,38,36,40,42,46,47,49";
const ActivitytypeidpayloadABH = "1,2,3,4,5,7,8,9,10,12,13,14,15,19,20,21,22,25,45,46,47,48,49,50,51,52,53,56,57,58,59,60,61,63,64,65,66,67,68,69"; // added new BoB Qlik Activity 24-02-2024 BRD  Actify new lead flow v1.2
const ActivitytypeidpayloadRBH ="1,2,3,4,5,7,8,9,10,12,13,14,15,19,20,21,22,25,45,46,47,48,49,50,51,52,53,56,57,58,59,60,61,63,64,65,66,67,68,69,75,76,77,78,79,80"; // added new RBH sales management"
const ActivitytypeidpayloadBDM = "1,2,3,4,5,7,8,9,10,12,13,14,15,19,20,21,22,25,26,27,29,30,31,45,46,47,48,49,50,51,52,53,54,56,57,58,59,60,61,63,64,65,66,67,68,69";
const ActivityStatusIdpayload = "1,2,3,4,5,9,10,11,12,13,16,17,18,19,20,21,24,25,26,27,28,29,30,33,34,35,38,36,40,41,42,43,44,46,47,48,49,50,";
const draftActivityTypeId = "1,2,3,4,5,7,8,10,11,9,12,13,14,15,19,20,21,22,23,24,25,45,46,47,48,49,50,51,52,53,54,56,57,58,59,60,61,69"; // added new BoB Qlik Activity 24-02-2024 BRD  Actify new lead flow v1.2
const draftActivityStatusId = "6,14,22,30,38,45,62,52";
const ActivityStatusPlannedAndRescheduledPayload = "1,3,9,11,17,19,25,27,33,35,47,49";
const ActivityCompletedPayload = "2,10,18,26,34,48";
const smabacklogActivityStatusId = "1,3,9,11,17,19,25,27,26,28,29,30,31,33,35,39,54,55";
const smabacklogActivityTypeId = "25,26,27,28,29,30,31,54,55,38,39,40,41,42,43,44,55,54";
const smabacklogActivityTypeIdRBH = "75,76,77,78,79,80";

const samActivityStatusIdpayload = "1,2,3,4,5,9,10,11,12,13,17,18,19,20,21,24,25,26,27,28,29,30,33,34,35,36,40,41,42,43,44,46";
const smaDraftActivityTypeId = "25,26,27,28,29,30,31,54,55,38,39,40,41,42,43,44";

const smaActivityOption = ["", "26", "27", "28", "29", "30", "31"]
const smaActivityOptionRBH = ["", "75", "76", "77", "78", "79", "80"]

const smaActivityOptionBroca = ["", "54", "55"]
function ActivityTable(props) {
  let location = useLocation();
  const isSMARoute = location.pathname.includes("smaActivities");
  const commonState = useSelector((commonstate) => commonstate);
  const classes = useStyles();
  const [isForSMA] = useState(isSMARoute);
  const [state, setState] = useState({ top: false });
  const autoCompleteItems = {
    branchNameAndCode: getDropdownDataBranch(
      [...props.masterDataState.Branches],
      "key",
      "label",
      "value"
    )
  }
  const [selectedType, setSelectedType] = useState({
    userType: "SELF",
    teamMember: "",
  });
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  const dispatch = useDispatch();
  const userState = commonState.userReducer;
  const userViewFor = commonState.commonReducer.viewForStatus;
  const GetBDMByABH = commonState.commonReducer.GetBDMByABH;//GetBDMByABH
  const [activitiesByUserTable, setActivitiesByUserTable] = useState([]);
  const [offlineActivities, setOfflineActivities] = useState([]);
  const history = useHistory();
  const [UserId] = useState(userState.profileInfo.userId);
  const fulln = `${userState.profileInfo.firstName} ${userState.profileInfo.lastName}`;
  const [UserFullName, setUserFullName] = useState(fulln);
  const [PageNo] = useState(1);
  const [NoOfRecordperPage, setNoOfRecordperPage] = useState(5);
  const [Noofdays] = useState(3650);
  const [TotalnoofRows, setTotalnoofRows] = useState(null);
  const { page } = commonState.updateActivityReducer.activityLandingPage;
  let activepage;
  if (page !== 0 && commonState.updateActivityReducer.filterby === "planned") {
    activepage = 2;
  } else {
    activepage = 0;
  }
  const [tabValue, setTabValue] = React.useState(activepage);

  const [NoOfDraftRecordperPage, setNoOfDraftRecordperPage] = useState(5);
  const [activitiesByUserDraftTable, setActivitiesByUserDraftTable] = useState(
    []
  );
  const [TotalnoofDraftRows, setTotalnoofDraftRows] = useState(null);

  const [NoOfBacklogRecordperPage, setNoOfBacklogRecordperPage] = useState(5);
  const [activitiesByUserBacklogTable, setActivitiesByUserBacklogTable] =
    useState([]);
  const [TotalnoofBacklogRows, setTotalnoofBacklogRows] = useState(null);
  const [selectedUser, setSelectedUser] = useState("");
  const isOnline = useOnlineStatus();
  const [loading, setLoading] = useState(false);
  const roleId = userState.profileInfo.roleId;
  let Activitytypeidpayload;
  if( roleId === "RBH" && isSMARoute && userState.profileInfo.channelid==1){
    Activitytypeidpayload = roleId === "RBH" ? ActivitytypeidpayloadRBH : ActivitytypeidpayloadBDM
  }else{
    Activitytypeidpayload = roleId === "ABH" ? ActivitytypeidpayloadABH : ActivitytypeidpayloadBDM
  }
  let smaActivitytypeidpayload;
  let smaDraftActivityTypeId;
      if(userState.profileInfo.roleId=="RBH" && userState.profileInfo.channelid==1){
      smaActivitytypeidpayload = "75,76,77,78,79,80";
      smaDraftActivityTypeId = "75,76,77,78,79,80";
      }else{
      smaActivitytypeidpayload ="25,26,27,28,29,30,31,54,55,38,39,40,41,42,43,44"
       smaDraftActivityTypeId = "25,26,27,28,29,30,31,54,55,38,39,40,41,42,43,44";
    }
  const channelid = userState.profileInfo.channelid;
  const loadMore = () => {
    const payload = {
      UserId: UserId ? UserId : filterState?.userName,
      PageNo: 1,
      NoOfRecordperPage: NoOfRecordperPage + 5,
      Noofdays: Noofdays,
      CreatedBy: null,
      Activitytypeid:
        (filterState.activityType !== "null" && filterState.activityType !== undefined) ? filterState.activityType : (isForSMA ? smaActivitytypeidpayload : Activitytypeidpayload),
      BranchId: filterState?.branchNameAndCode,
      ActivityStatusId:
        commonState.updateActivityReducer.filterby === "planned"
          ? ActivityStatusPlannedAndRescheduledPayload
          : commonState.updateActivityReducer.filterby === "completed"
            ? ActivityCompletedPayload
            : (filterState.activityStatus !== "" && filterState.activityStatus !== null && filterState.activityStatus !== undefined) ? filterState.activityStatus : (isForSMA ? samActivityStatusIdpayload : ActivityStatusIdpayload),
      Modifieddate:
        (filterState?.lastUpdateDate !== "null" && filterState?.lastUpdateDate !== undefined)
          ? filterState.lastUpdateDate
          : null,
      IsBacklog: false,
      Createstartdate:
        (filterState.CreatedDateStartTIme !== "null" && filterState?.CreatedDateStartTIme !== undefined)
          ? filterState.CreatedDateStartTIme
          : null,
      Createenddate:
        (filterState.CreatedDateEndTIme !== "null" && filterState?.CreatedDateEndTIme !== undefined)
          ? filterState.CreatedDateEndTIme
          : null,
      MeetingStartdate:
        (filterState.fromdate !== "null" && filterState.fromdate !== undefined) ? filterState.fromdate : null,
      MeetingEnddate: filterState.todate !== "null" ? filterState.todate : null,
    };

    if (isOnline) {
      const { userType, teamMember } = selectedType;
      fetchActivityTableData(
        payload,
        UserId,
        userType,
        userType === "BDM" && teamMember
      );
      setNoOfRecordperPage(NoOfRecordperPage + 5);
    }
  };
  const loadMoreBacklog = () => {
    let startdate = null;
    let enddate = null;
    if (
      backlogfilterState.lastdate !== "null" &&
      backlogfilterState.lastdate !== "duration" && backlogfilterState.lastdate !== undefined
    ) {
      const lastdatearr = backlogfilterState.lastdate.split(",");
      startdate = lastdatearr[0] ? lastdatearr[0] : null;
      enddate = lastdatearr[1] ? lastdatearr[1] : null;
    } else if (backlogfilterState.lastdate === "duration") {
      startdate = backlogfilterState.formdate
        ? backlogfilterState.formdate
        : null;
      enddate = backlogfilterState.todate ? backlogfilterState.todate : null;
    }
    let tempsmabacklogActivityTypeId;
    if(isForSMA && roleId === "RBH" && channelid === 1){
      tempsmabacklogActivityTypeId=smabacklogActivityTypeIdRBH;
    }else{
      tempsmabacklogActivityTypeId=smabacklogActivityTypeId;
    }
    const backlogpayload = {
      UserId: UserId ? UserId : backlogfilterState?.userName,
      PageNo: 1,
      NoOfRecordperPage: NoOfBacklogRecordperPage + 5,
      Noofdays: Noofdays,
      CreatedBy: null,
      //Activitytypeid: (backlogfilterState.activityType !== "null" && backlogfilterState.activityType !== undefined && backlogfilterState.activityType !== '') ? backlogfilterState.activityType : (isForSMA ? smabacklogActivityTypeId : Activitytypeidpayload),
      Activitytypeid: (backlogfilterState.activityType !== "null" && backlogfilterState.activityType !== undefined && backlogfilterState.activityType !== '') ? backlogfilterState.activityType : (isForSMA ? tempsmabacklogActivityTypeId : Activitytypeidpayload),
      BranchId: backlogfilterState?.branchNameAndCode !== "" ? backlogfilterState?.branchNameAndCode : 0,
      Createddate: null,
      MeetingDate: null,
      Modifieddate: null,
      MeetingStartdate: startdate,
      MeetingEnddate: enddate,
      IsBacklog: true,
      ActivityStatusId: `${isForSMA ? smabacklogActivityStatusId : backlogActivityStatusId}`,
    };
    const { userType, teamMember } = selectedType;
    fetchActivityTableBaklogData(backlogpayload,
      UserId,
      userType,
      userType === "BDM" && teamMember);
    setNoOfBacklogRecordperPage(NoOfBacklogRecordperPage + 5);
  };
  async function fetchActivityTableData(payload, userId, userType, teamMember) {
    if (isOnline) {
      props.activateLoader();
      const result = await props.getActivityListByIdForTable(
        payload,
        userId,
        userState?.profileInfo?.roleId,
        teamMember ? userType : userViewFor !== "" ? userViewFor : userType,
        `${teamMember ? teamMember : GetBDMByABH}`, //(GetBDMByABH !== 'all' ? GetBDMByABH : '')
      );
      if (result.status === 200) {
        props.deActivateLoader();
        setActivitiesByUserTable(result.data.GetactivitybyactivitybyUserID);
        setTotalnoofRows(result.data.TotalnoofRows);
      } else {
        props.deActivateLoader();
      }
    }
  }
  async function fetchActivityTableDraftData(payload) {
    if (isOnline) {
      props.activateLoader();
      const result = await props.getActivityListByIdForDraftTable(
        payload,
        UserId,
        userState?.profileInfo?.roleId,
        userViewFor,
        `${GetBDMByABH}`
      );
      if (result.status === 200) {
        props.deActivateLoader();
        setActivitiesByUserDraftTable(
          result.data.GetactivitybyactivitybyUserID
        );
        setTotalnoofDraftRows(result.data.TotalnoofRows);
      } else {
        props.deActivateLoader();
      }
    }
  }
  async function fetchActivityTableBaklogData(payload, userId, userType, teamMember) {
    if (isOnline) {
      props.activateLoader();
      const result = await props.getActivityListByIdForBacklogTable(
        payload,
        userId,
        userState?.profileInfo?.roleId,
        teamMember ? userType : userViewFor !== "" ? userViewFor : userType,
        `${teamMember ? teamMember : GetBDMByABH}`
      );
      if (result.status === 200) {
        props.deActivateLoader();
        setActivitiesByUserBacklogTable(
          result.data.GetactivitybyactivitybyUserID
        );
        setTotalnoofBacklogRows(result.data.TotalnoofRows);
      } else {
        props.deActivateLoader();
      }
    }
  }

  async function deleteActivityListByIdForDraft(draftfor, ids) {
    if (isOnline) {
      props.activateLoader();
      const result = await props.deleteActivityListByIdForDraftTable(draftfor, ids);
      if (result.status === 200) {
        props.deActivateLoader();
        const drafpayload = {
          UserId: UserId,
          PageNo: PageNo,
          NoOfRecordperPage: NoOfDraftRecordperPage,
          Noofdays: Noofdays,
          CreatedBy: null,
          Activitytypeid: isForSMA ? smaDraftActivityTypeId : draftActivityTypeId,
          BranchId: 0,
          Createddate: null,
          MeetingDate: null,
          ActivityStatusId: draftActivityStatusId,
          Modifieddate: null,
          IsBacklog: false,
        };
        fetchActivityTableDraftData(drafpayload);
      }
    }
  }

  const clearSetFilterBy = async () => {
    console.log(selectedUser)
    await props.setFilterBy("");
  };

  useEffect(() => {
    const req = {
      UserId: UserId,
      PageNo: PageNo,
      NoOfRecordperPage: NoOfRecordperPage,
      Noofdays: Noofdays,
      CreatedBy: null,
      Activitytypeid: isForSMA ? smaActivitytypeidpayload : Activitytypeidpayload,
      BranchId: 0,
      MeetingDate: null,
      ActivityStatusId:
        commonState.updateActivityReducer.filterby === "planned"
          ? ActivityStatusPlannedAndRescheduledPayload
          : commonState.updateActivityReducer.filterby === "completed"
            ? ActivityCompletedPayload
            : isForSMA ? samActivityStatusIdpayload : ActivityStatusIdpayload,
      Modifieddate: null,
      IsBacklog: false,
      Createstartdate: null,
      Createenddate: null,
      MeetingStartdate: null,
      MeetingEnddate: null,
    };
    const drafpayload = {
      UserId: UserId,
      PageNo: PageNo,
      NoOfRecordperPage: NoOfDraftRecordperPage,
      Noofdays: Noofdays,
      CreatedBy: null,
      Activitytypeid: isForSMA ? smaDraftActivityTypeId : draftActivityTypeId,
      BranchId: 0,
      Createddate: null,
      MeetingDate: null,
      ActivityStatusId: draftActivityStatusId,
      Modifieddate: null,
      IsBacklog: false,
    };
    const backlogpayload = {
      UserId: UserId,
      PageNo: 1,
      NoOfRecordperPage: NoOfBacklogRecordperPage,
      Noofdays: Noofdays,
      CreatedBy: null,
      Activitytypeid: isForSMA ? smaActivitytypeidpayload : Activitytypeidpayload,
      BranchId: 0,
      Createddate: null,
      MeetingDate: null,
      IsBacklog: true,
      ActivityStatusId: `${isForSMA ? smabacklogActivityStatusId : ActivityStatusIdpayload}`,
      Modifieddate: null,
    };
    if (isOnline) {
      if (roleId !== ROLE_BDM && roleId !== ROLE_FPM) {
        getSub();
      }
      getOptionData();
      if (tabValue == 0) {
        fetchActivityTable(req, UserId, "SELF");
      } else if (tabValue == 1) {
        fetchActivityTableDraftData(drafpayload);
      } else {
        fetchActivityTableBaklogData(backlogpayload, UserId, "SELF");
      }

    } else {
      setLoading(true);
      getOptionData();
      getAllActivityOffline().then((result) => {
        setLoading(false);
        const activityList = result.sort((a, b) => new Date(b.CreatedDate) - new Date(a.CreatedDate))
        if (activityList.length > 0) {
          setOfflineActivities(activityList);
          setActivitiesByUserTable(activityList);
          setTotalnoofRows(activityList.length);
        }
      }).catch(error => setLoading(false));
    }

    async function fetchActivityTable(reqst, UserID, userType, teamMember) {
      if (isOnline) {
        props.activateLoader();
        const result = await props.getActivityListByIdForTable(
          reqst,
          UserID,
          userState?.profileInfo?.roleId,
          teamMember ? userType : userViewFor !== "" ? userViewFor : userType,
          `${teamMember ? teamMember : GetBDMByABH}`, //(GetBDMByABH !== 'all' ? GetBDMByABH : '')
        );
        if (result.status === 200) {
          props.deActivateLoader();
          setActivitiesByUserTable(result.data.GetactivitybyactivitybyUserID);
          setTotalnoofRows(result.data.TotalnoofRows);
        } else {
          props.deActivateLoader();
        }
      }
    }
  }, [tabValue])
  const [newUserList, setNewUserList] = useState([{
    key: UserId,
    label: UserFullName,
    value: UserId
  }])

  useEffect(() => {
    setFilterState({
      userName: UserFullName
    })
    setbacklogFilterState({
      userName: UserFullName
    })
  }, [UserFullName]);
  const [filterState, setFilterState] = useState({
    userName: UserFullName,
    activityType: isForSMA ? smaActivitytypeidpayload : Activitytypeidpayload, //"1,2,3,4,5"
    branchNameAndCode: 0,
    createdOn: "null",
    meetingOn: "null",
    activityStatus: isForSMA ? samActivityStatusIdpayload : ActivityStatusIdpayload,
    lastUpdateDate: "null",
    CreatedDateStartTIme: "null",
    CreatedDateEndTIme: "null",
    fromdate: "null",
    todate: "null",
    Activitytypeid: null,
    LeadTypeID: [],
  });

  const [backlogfilterState, setbacklogFilterState] = useState({
    userName: UserFullName,
    activityType: isForSMA ? smaActivitytypeidpayload : Activitytypeidpayload,
    branchNameAndCode: 0,
    meetingOn: "null",
    formdate: "null",
    todate: "null",
    activityStatus: `${isForSMA ? smabacklogActivityStatusId : backlogActivityStatusId}`,
    lastUpdateDate: "null",
    lastdate: "null",
  });

  useEffect(() => {
    // const { roleId, channelid } = userState?.profileInfo;

    if (roleId === ROLE_ABH) {
      filterFormData[0].hide = false;
      BacklogfilterFormData[0].hide = false;
    }
    if (channelid === 2) {
      filterFormData[4].hide = true
    } else {
      filterFormData[4].hide = false
    }
    if (channelid === 3) {
      filterFormData.map((option) => {
        if (option['key'] === "branchNameAndCode") { option['hide'] = true; }
      })
    }
    if ((roleId === "RBH" || roleId === "ZBH" || roleId === "NH") && channelid == 2) {
      BacklogfilterFormData[4].hide = true;
    }
  }, [userState]);


  const onAutocompleteSelectHandler = (key, value, item) => {
    if (key === "branchNameAndCode") {
      setFilterState({
        branchNameAndCode: item.value
      })
    }
  }

  const getSub = async () => {
    const result = await props.getSubordinateList({
      userId: userState.profileInfo.userId,
    });
    if (result.status === 200) {
      const r = result.data.map((el) => {
        return {
          key: el.userID,
          label: el.userName,
          value: el.userID,
        };
      });
      r.unshift({
        key: 'all',
        label: "select",
        value: "all",
      })

      setNewUserList([...newUserList, ...r])

      filterFormData.map(item => {
        if (item.name === "teamMember") {
          item.options = [...r];
        }
        return item;
      });

      BacklogfilterFormData.map((item) => {
        if (item.name === "teamMember") {
          item.options = [...r];
        }
        return item;
      });
      if (GetBDMByABH !== 'all') {
        const res = result.data.filter((el) => {
          if (GetBDMByABH == el.userID)
            return true;
        });
        setUserFullName(res[0].userName)
        filterFormData[0].hide = true;
        filterFormData[1].hide = true;
        filterFormData[2].hide = false;
        BacklogfilterFormData[0].hide = true;
        BacklogfilterFormData[1].hide = true;
        BacklogfilterFormData[2].hide = false;

      } else {
        filterFormData[1].hide = true;
        filterFormData[2].hide = false;
        BacklogfilterFormData[1].hide = true;
        BacklogfilterFormData[2].hide = false;
      }
    }

    if (filterState.userType === "team") {
      filterFormData[1].hide = false;
      filterFormData[2].hide = true;
      BacklogfilterFormData[1].hide = false;
      BacklogfilterFormData[2].hide = true;
      dispatch({
        type: "viewFor",
        payload: "",
      });
      setFilterState({
        ...filterState,
        userType: "team",
      });
    }
  };
  const getOptionData = () => {
    filterFormData.map(item => {
      if (item.name === "activityType") {
        const Activity_type = (channelid !== 3) ? (channelid === 79 ? MSME_BDM_ACTIVITY_FILTER : (roleId === "FPM") ? BANCA_FPH_ACTIVITY :  BANCA_BDM_ACTIVITY) : AGENCY_ACTIVITY;
          let filter_arr = [];
        if (
          props.masterDataState.Activities &&
          props.masterDataState.Activities.length > 0
        ) {
          filter_arr = props.masterDataState.Activities.filter((option) => {
            if (Activity_type.includes(option.value)) {
              return option;
            }
          });
          if (tabValue == 2) {
            filter_arr = filter_arr?.filter(opt => opt.value !== '6')
          }
          // for FPM user Branch foundation day is not required
          if (roleId === "FPM") {
            filter_arr = filter_arr.filter(el => el.value !== '5');
          }

          if (roleId === "RBH" || roleId === "ZBH" || roleId === "NH") {
            if (channelid === 1) {
              filter_arr = filter_arr.filter(el => (el.value === '' || el.value === '1' || el.value === '6'));
            }
          }
        }
        if (channelid !== 13 && channelid !== 2) {
          // item.options = filter_arr;
          if (isForSMA && channelid !== 21) {
            item.options = props.masterDataState.Activities.filter((val) => {
              if(channelid == 1 && roleId === "RBH"){
                return smaActivityOptionRBH.includes(val.value)
              }else{
                return smaActivityOption.includes(val.value)
              }
           });
          }
          else if (isForSMA && channelid === 21) {
            item.options = props.masterDataState.Activities.filter((val) => {
              return smaActivityOptionBroca.includes(val.value)
            });
          } else if (!isForSMA && channelid === 21) {
            item.options = props.masterDataState.Activities.filter((val) => {
              return BROCA_COMMON_ACTIVITY_TYPES_ID.includes(val.value)
            });
          }else if(channelid === 19){
            item.options = props.masterDataState.Activities;
          } else {
            item.options = filter_arr
          }
        } else {
          if (isForSMA && channelid !== 21) {
            item.options = props.masterDataState.Activities.filter((val) => {
              return smaActivityOption.includes(val.value)
            });
          }
          else if (isForSMA && channelid === 21) {
            item.options = props.masterDataState.Activities.filter((val) => {
              return smaActivityOptionBroca.includes(val.value)
            });
          }
          else {
            item.options = props.masterDataState.Activities;
          }
        }
      } else if (item.name === "branchNameAndCode") {
        if (userState.profileInfo.channelid === 3) {
          item.hide = true;
        }
      } else if (item.name === "activityStatus") {
        const getDropdownData = (optionsArr, key, label, value) => {
          let arr = [];
          if (optionsArr && optionsArr.length > 0) {
            arr = optionsArr.map((option) => {
              return { key: option[key], label: option[label], value: option[value] };
            });
          }
          arr.unshift({ key: "select", label: "Select", value: "" });
          return arr;
        };

        let arr_status = props.masterDataState.ActivityStatus.filter(f => f.value != 5 && f.value != 6 && f.value != 7 && f.value != 21 && f.value != 22 && f.value != 23 && f.value != 29 && f.value != 30 && f.value != 31 && f.value != 13 && f.value != 14 && f.value != 15 && f.value != 24 && f.value != 16 
          && f.value != 38 && f.value != 39 && f.value != 45 && f.value != 46 && f.value != 51 && f.value != 52 && f.value != 53 && f.value != '')

        item.options = getDropdownData(
          arr_status,
          "label",
          "label",
          "value"
        );
      } else if (item.name === "userName") {
        if (userState?.profileInfo?.roleId !== ROLE_BDM) {
          item.disabled = false;
        } else {
          item.disabled = true;
        }
        item.options = newUserList;
      } else if (item.name === "userType") {
        item.options = USER_TYPE;
      }
      return item;
    });

    BacklogfilterFormData.map(item => {
      if (item.name === "activityType") {

        const Activity_type = (channelid !== 3) ? (channelid === 79 ? MSME_BDM_ACTIVITY_FILTER : (channelid === 13 ? GCL_BDM_ACTIVITY_FILTER : (roleId === "FPM") ? BANCA_FPH_ACTIVITY :(roleId === "RBH" && channelid==1 && isForSMA) ? BANCA_RBH_SMA_ACTIVITY_NEW: BANCA_BDM_ACTIVITY)) : AGENCY_ACTIVITY;

        let filter_arr = [];
        if (
          props.masterDataState.Activities &&
          props.masterDataState.Activities.length > 0
        ) {
          filter_arr = props.masterDataState.Activities.filter((option) => {
            if (Activity_type.includes(option.value)) {
              return option;
            }
          });

          if (tabValue == 2 && (roleId !== "RBH" && roleId !== "ZBH" && roleId !== "NH")) {
            filter_arr = filter_arr?.filter(opt => (opt.value !== '6' && opt.value !== "24" && opt.value != "62"))
          }
          if (roleId === "RBH" || roleId === "ZBH" || roleId === "NH") {
            if (channelid === 1) {
              filter_arr = filter_arr.filter(el => (el.value === '' || el.value === '1' || el.value === '6'));
            }
          }
          // for FPM user Branch foundation day is not required
          if (roleId === "FPM") {
            filter_arr = filter_arr.filter(el => el.value !== '5');
          }
        }
        if (channelid !== 13 && channelid !== 2) {
          // item.options = filter_arr;
          if (isForSMA && channelid !== 21) {
            item.options = props.masterDataState.Activities.filter((val) => {
              if(channelid == 1 && roleId === "RBH"){
                return smaActivityOptionRBH.includes(val.value)
              }else{
                return smaActivityOption.includes(val.value)
              }
            });
          }
          else if (isForSMA && channelid === 21) {
            item.options = props.masterDataState.Activities.filter((val) => {
              return smaActivityOptionBroca.includes(val.value)
            });
          }
          else if (!isForSMA && channelid === 21) {
            item.options = props.masterDataState.Activities.filter((val) => {
              return BROCA_COMMON_ACTIVITY_TYPES_ID.includes(val.value)
            });
          }
          else {
            item.options = filter_arr
          }
        } else {
          if (isForSMA && channelid !== 21) {
            item.options = props.masterDataState.Activities.filter((val) => {
              return smaActivityOption.includes(val.value)
            });
          }
          else if (isForSMA && channelid === 21) {
            item.options = props.masterDataState.Activities.filter((val) => {
              return smaActivityOptionBroca.includes(val.value)
            });
          }
          else {
            if (channelid !== 13) {
              item.options = props.masterDataState.Activities;
            } else {
              item.options = filter_arr;
            }
          }
        }
      } else if (item.name === "branchNameAndCode") {
        if (userState.profileInfo.channelid === 3) {
          item.hide = true;
        }
      } else if (item.name === "activityStatus") {
        item.options = props.masterDataState.ActivityStatus;
      } else if (item.name === "userName") {
        if (userState?.profileInfo?.roleId !== ROLE_BDM) {
          item.disabled = false;
        } else {
          item.disabled = true;
        }
        item.options = newUserList;
      } else if (item.name === "lastdate") {
        item.options = LEAD_DATE;
      } else if (item.name === "userType") {
        item.options = USER_TYPE;
      }
      return item;
    });
  };
  const resetFilters = (anchor) => {
    filterFormData[7].hide = true;
    filterFormData[6].hide = true;
    filterFormData[9].hide = true;
    filterFormData[10].hide = true;
    clearSetFilterBy();
    const payload = {
      UserId: userState.profileInfo.userId,
      PageNo: 1,
      NoOfRecordperPage: NoOfRecordperPage,
      Noofdays: null,
      CreatedBy: null,
      Activitytypeid: isForSMA ? smaActivitytypeidpayload : Activitytypeidpayload,
      BranchId: 0,
      MeetingDate: null,
      ActivityStatusId: isForSMA ? samActivityStatusIdpayload : ActivityStatusIdpayload,
      Modifieddate: null,
      IsBacklog: false,
      Createstartdate: null,
      Createenddate: null,
      MeetingStartdate: null,
      MeetingEnddate: null,
    };
    setSelectedType({
      userType: userViewFor,
      teamMember: '',
    });

    if (isOnline) {
      fetchActivityTableData(payload, UserId, "SELF");
    } else {
      setActivitiesByUserTable(offlineActivities);
      setTotalnoofRows(offlineActivities.length);
    }
    setFilterState({
      userName: UserFullName,
      activityType: "null",
      branchNameAndCodelabel: "",
      branchNameAndCode: null,
      createdOn: "null",
      meetingOn: "null",
      activityStatus: null,
      lastUpdateDate: "null",
      CreatedDateStartTIme: "null",
      CreatedDateEndTIme: "null",
      fromdate: "null",
      todate: "null",
      userType: "self",
      teamMember: "0",
      ActivityStatusId: null,
      Activitytypeid: null,
      LeadTypeID: []
    });
  };
  const onSubmitHandler = (model, anchor) => {
    console.log(filterState)
    clearSetFilterBy()
    const { userName, activityType, branchNameAndCode, createdOn, meetingOn,
      lastUpdateDate, teamMember, LeadTypeID } = model;
    const branchNameValue = [...props.masterDataState.Branches].some((el) => el.value === branchNameAndCode)
    const activity_status = LeadTypeID?.map((val) => {
      return val.value;
    })
    let ActivityMultiStausts = activity_status?.join(',');
    if (ActivityMultiStausts === "" || ActivityMultiStausts === 'undefined' || ActivityMultiStausts === undefined) {
      ActivityMultiStausts = 'null'
    }
    let meetingstart = null;
    let meetingend = null;
    let createdstart = null;
    let createdend = null;
    if (meetingOn !== "null" && meetingOn !== "duration" && meetingOn !== undefined) {
      const lastdatearr = model.meetingOn.split(",");
      meetingstart = lastdatearr[0] ? lastdatearr[0] : null;
      meetingend = lastdatearr[1] ? lastdatearr[1] : null;
    }
    if (createdOn !== "null" && createdOn !== "duration" && createdOn !== undefined) {
      const lastdatearr = model.createdOn.split(",");
      createdstart = lastdatearr[0] ? lastdatearr[0] : null;
      createdend = lastdatearr[1] ? lastdatearr[1] : null;
    }

    if (meetingOn === "duration") {
      if (model.fromdate === "null" || model.todate === "null") {
        dispatch(
          props.activateErrorMessageBar(
            "Please Select Value For Both Meeting Start Date and End Date"
          )
        );
        return false;
      }
      meetingstart = model.fromdate;
      meetingend = model.todate;
    }
    if (createdOn === "duration") {
      if (
        model.CreatedDateStartTIme === "null" ||
        model.CreatedDateEndTIme === "null"
      ) {
        dispatch(
          props.activateErrorMessageBar(
            "Please Select Value For Both Created Start Date and End Date"
          )
        );
        return false;
      }
      createdstart = model.CreatedDateStartTIme;
      createdend = model.CreatedDateEndTIme;
    }
    model.fromdate = meetingstart;
    model.todate = meetingend;

    model.CreatedDateStartTIme = createdstart;
    model.CreatedDateEndTIme = createdend;
    if (activityType !== "" || branchNameAndCode !== 0 || createdOn !== "null" ||
      meetingOn !== "null" || ActivityMultiStausts !== "null") {
      const payload = {
        "UserId": userState.profileInfo.userId,
        "PageNo": 1,
        "NoOfRecordperPage": NoOfRecordperPage,
        "Noofdays": Noofdays,
        "CreatedBy": null,
        "Activitytypeid": activityType && activityType != "null" ? activityType : (isForSMA ? smaActivitytypeidpayload : Activitytypeidpayload),
        "BranchId": branchNameValue ? branchNameAndCode : 0,
        "ActivityStatusId": ActivityMultiStausts !== 'null' ? ActivityMultiStausts : (isForSMA ? samActivityStatusIdpayload : ActivityStatusIdpayload),
        "Modifieddate": (lastUpdateDate !== "null" && lastUpdateDate !== "" && lastUpdateDate !== undefined) ? moment(lastUpdateDate).format(meetingDateFormate) : null,
        "IsBacklog": false,
        "Createstartdate": model.CreatedDateStartTIme !== "null" ? model.CreatedDateStartTIme : null,
        "Createenddate": model.CreatedDateEndTIme !== "null" ? model.CreatedDateEndTIme : null,
        "MeetingStartdate": model.fromdate !== "null" ? model.fromdate : null,
        "MeetingEnddate": model.todate !== "null" ? model.todate : null,

      }
      // return false
      if (isOnline) {
        const user_type = model.userType === "team" ? "BDM" : "SELF";
        fetchActivityTableData(
          payload,
          UserId,
          model.userType === "team" ? "BDM" : model.userType,
          model.userType === "team" && teamMember
        );
        setSelectedType({
          userType: user_type,
          teamMember: teamMember,
        });
      } else {
        // filter activity list offline
        const filterActivities = getFilterActivities(
          activitiesByUserTable,
          payload
        );
        setActivitiesByUserTable(filterActivities);
        setTotalnoofRows(filterActivities.length);
      }
      setFilterState({
        userName: userName,
        activityType: activityType,
        branchNameAndCode: branchNameAndCode,
        createdOn: createdOn,
        meetingOn: meetingOn,
        activityStatus: ActivityMultiStausts !== 'null' ? ActivityMultiStausts : (isForSMA ? samActivityStatusIdpayload : ActivityStatusIdpayload),
        lastUpdateDate: lastUpdateDate,
        fromdate: model.fromdate,
        todate: model.todate,
        CreatedDateStartTIme: model.CreatedDateStartTIme,
        CreatedDateEndTIme: model.CreatedDateEndTIme,
      });
      if (anchor) {
        setState({ ...state, [anchor]: false });
      }
    } else {
      props.activateErrorMessageBar("Please apply atleast any one option");
    }
  };

  const onFilterChange = (event, key) => {

    switch (key) {
      case "userType":
        const getUserType = event.target.value;
        if (getUserType === "team") {
          filterFormData[1].hide = false;
          filterFormData[2].hide = true;
          // dispatch({
          //   type: "viewFor",
          //   payload: "",
          // });
          setFilterState({
            ...filterState,
            userType: "team",
          });
        } else {
          filterFormData[1].hide = true;
          filterFormData[2].hide = false;
        }
        break;
      case "createdOn":
        if (event.target.value === "duration") {
          filterFormData[7].hide = false;
          filterFormData[6].hide = false;
          setFilterState({
            ...filterState,
            createdOn: event.target.value,
            CreatedDateStartTIme: moment(new Date()).format(meetingDateFormate),
            CreatedDateEndTIme: moment(new Date()).format(meetingDateFormate),
          });

        } else {
          filterFormData[7].hide = true;
          filterFormData[6].hide = true;
          setFilterState({
            ...filterState,
            createdOn: event.target.value,
            CreatedDateStartTIme: "null",
            CreatedDateEndTIme: "null",
          });
        }
        break;

      case "meetingOn":
        if (event.target.value === "duration") {
          filterFormData[9].hide = false;
          filterFormData[10].hide = false;
          setFilterState({
            ...filterState,
            meetingOn: event.target.value,
            fromdate: moment(new Date()).format(meetingDateFormate),
            todate: moment(new Date()).format(meetingDateFormate),
          });
        } else {
          filterFormData[9].hide = true;
          filterFormData[10].hide = true;
          setFilterState({
            ...filterState,
            meetingOn: event.target.value,
            fromdate: null,
            todate: null,
          });
        }
        break;
      case "CreatedDateStartTIme":
        filterFormData[7].min = event.target.value;
        break;
      case "fromdate":
        filterFormData[9].min = event.target.value;
        break;
      case "userName":
        setSelectedUser(event.target.value);
        setbacklogFilterState({
          username: event.target.value,
        });
        dispatch({
          type: "viewFor",
          payload: "BDM",
        });
        break;
      default:
        break;
    }
  };

  const resetBacklogFilters = (anchor) => {
    const payload = {
      UserId: userState.profileInfo.userId,
      PageNo: 1,
      NoOfRecordperPage: NoOfBacklogRecordperPage,
      Noofdays: Noofdays,
      CreatedBy: null,
      Activitytypeid: isForSMA ? smaActivitytypeidpayload : Activitytypeidpayload,
      BranchId: 0,
      Createddate: null,
      MeetingDate: null,
      Modifieddate: null,
      MeetingStartdate: null,
      MeetingEnddate: null,
      ActivityStatusId: `${isForSMA ? smabacklogActivityStatusId : backlogActivityStatusId}`,
      IsBacklog: true,
      branchNameAndCode: null
    };
    setSelectedType({
      userType: userViewFor,
      teamMember: '',
    });
    fetchActivityTableBaklogData(payload, UserId);
    setbacklogFilterState({
      userName: "",
      branchNameAndCodelabel: "",
      createdOn: "null",
      meetingOn: "null",
      lastUpdateDate: "null",
      lastdate: "null",
      IsBacklog: true,
      formdate: "null",
      todate: "null",
      activityType: "null",
      branchNameAndCode: null
    });
    BacklogfilterFormData[6].hide = true;
    BacklogfilterFormData[7].hide = true;
  };

  const onBacklogSubmitHandler = (model, anchor) => {
    const {
      userName,
      activityType,
      branchNameAndCode,
      lastdate,
      formdate,
      todate,
    } = model;
    const branchNameValue = [...props.masterDataState.Branches].some((el) => el.value === branchNameAndCode)
    if (
      userName === "" &&
      activityType === 0 &&
      branchNameAndCode === 0 &&
      lastdate === "null"
    ) {
      props.activateErrorMessageBar("Please apply atleast any one option");
    } else {
      let startdate = null;
      let enddate = null;
      if (lastdate !== "null" && lastdate !== "duration" && lastdate !== undefined) {
        const lastdatearr = model.lastdate.split(",");
        startdate = lastdatearr[0] ? lastdatearr[0] : null;
        enddate = lastdatearr[1] ? lastdatearr[1] : null;
      } else if (lastdate === "duration") {
        startdate = formdate ? formdate : null;
        enddate = todate ? todate : null;
      }
      const payload = {
        UserId: userState.profileInfo.userId,
        PageNo: 1,
        NoOfRecordperPage: NoOfBacklogRecordperPage,
        Noofdays: Noofdays,
        CreatedBy: null,
        Activitytypeid: activityType !== "null" ? activityType : (isForSMA ? smaActivitytypeidpayload : Activitytypeidpayload),
        BranchId: branchNameValue ? branchNameAndCode : 0,
        Createddate: null,
        MeetingDate: null,
        Modifieddate: null,
        MeetingStartdate: startdate,
        MeetingEnddate: enddate,
        IsBacklog: true,

        ActivityStatusId: `${isForSMA ? smabacklogActivityStatusId : backlogActivityStatusId}`,
      };

      const user_type = model.userType === "team" ? "BDM" : "SELF";

      fetchActivityTableBaklogData(payload,
        UserId,
        model.userType === "team" ? "BDM" : model.userType,
        model.userType === "team" && model.teamMember);
      setSelectedType({
        userType: user_type,
        teamMember: model.teamMember,
      });
      setbacklogFilterState({
        userName: userName,
        activityType: activityType,
        branchNameAndCode: branchNameAndCode,
        lastUpdateDate: "null",
        lastdate: lastdate,
        formdate: formdate,
        todate: todate,
      });
      if (anchor) {
        setState({ ...state, [anchor]: false });
      }
    }
  };
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    BacklogfilterFormData[6].hide = true;
    BacklogfilterFormData[7].hide = true;
  };
  return (
    <div className="activityList">
      <div className="tabBox">
        <Tabs
          value={tabValue}
          style={{ marginTop: "46px" }}
          onChange={handleTabChange}
          aria-label="tabs example"
        >
          <Tab label="Activity" {...a11yProps(0)} />
          <Tab label="Drafts" {...a11yProps(1)} />
          <Tab label="Backlog" {...a11yProps(2)} />
        </Tabs>

      </div>
      <TabPanel value={tabValue} index={0}>
        <Grid className="activiPad0" container columns={{ xs: 4, md: 12 }}>
          <Grid item xs={12} md={9} className="leftCol">
            <div className="topMenus">
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <button
                    className="addItem"
                    onClick={() => history.push("/app/activity/create")}
                  >
                    +
                  </button>
                </Grid>
                <Grid item xs={12} md={6}>
                  <div className="rightPanel">
                    <span>
                      {" "}
                      {NoOfRecordperPage < TotalnoofRows
                        ? NoOfRecordperPage
                        : TotalnoofRows}{" "}
                    </span>
                    /<span>{TotalnoofRows} Results</span>
                  </div>
                </Grid>
              </Grid>
            </div>
            {activitiesByUserTable.map((item) => {
              return <Card isForSMA={isForSMA} key={item.TrnActivityID} item={item} />;
            })}
            {loading ? (
              <h4>Loading...</h4>
            ) : (
              <>
                {NoOfRecordperPage <= TotalnoofRows ? (
                  <button className="btn loadMore" onClick={loadMore}>
                    Load More
                  </button>
                ) : (
                  <div className="noMoreDiv">No more records to load</div>
                )}
              </>
            )}
          </Grid>
          <Grid item xs={12} md={3} className="rightCol">
            <div className={classes.desktopFilter}>
              <div className="filterBox">
                <h4 className="filterSec">
                  {" "}
                  <FilterAltIcon className="icnFilter" /> Filter By
                </h4>
                <DynamicForm
                  key={"filterId"}
                  title={" "}
                  buttonSubmit={"APPLY FILTER"}
                  defaultValues={filterState}
                  model={filterFormData}
                  onSubmit={(model) => {
                    onSubmitHandler(model);
                  }}
                  disableSubmitOnInValid={false}
                  design="bootrstap" // material/bootrstap/advanceMaterial/advanceBootstrap
                  materialOutline={false}
                  onChange={(event, key) => onFilterChange(event, key)}
                  onAutocompleteSelect={onAutocompleteSelectHandler}
                  autoCompleteItems={autoCompleteItems}
                  showAstersikOnRequiredFields={true}
                  className="activityFilters"
                  extaraActionFormButton={
                    <span className="reset" onClick={resetFilters}>
                      <RestartAltIcon /> Reset
                    </span>
                  }
                />
              </div>
            </div>
            <span className={classes.drawerMobile}>
              {["top"].map((anchor) => (
                <React.Fragment key={anchor}>
                  <Box
                    className="editBtn"
                    sx={{ "& > :not(style)": { m: 1 } }}
                    onClick={toggleDrawer(anchor, true)}
                  >
                    <span className="icnFilterBox">
                      {" "}
                      <FilterAltIcon />{" "}
                    </span>
                  </Box>
                  <SwipeableDrawer
                    anchor={anchor}
                    open={state[anchor]}
                    onClose={toggleDrawer(anchor, false)}
                    onOpen={toggleDrawer(anchor, true)}
                  >
                    <div className="filterBox">
                      <h4 className="filterSec">
                        <FilterAltIcon className="icnFilter" /> Filter By{" "}
                        <span
                          style={{ float: "right" }}
                          onClick={toggleDrawer(anchor, false)}
                        >
                          <CloseIcon />
                        </span>
                      </h4>
                      <DynamicForm
                        key={"filterId"}
                        title={" "}
                        buttonSubmit={"APPLY FILTER"}
                        defaultValues={filterState}
                        model={filterFormData}
                        onSubmit={(model) => {
                          onSubmitHandler(model, anchor);
                        }}
                        disableSubmitOnInValid={false}
                        design="bootrstap" // material/bootrstap/advanceMaterial/advanceBootstrap
                        materialOutline={false}
                        onChange={(event, key) => onFilterChange(event, key)}
                        onAutocompleteSelect={onAutocompleteSelectHandler}
                        autoCompleteItems={autoCompleteItems}
                        showAstersikOnRequiredFields={true}
                        className="activityFilters activityFiltersMobile"
                        extaraActionFormButton={
                          <span
                            className="reset"
                            onClick={() => resetFilters(anchor)}
                            style={{ marginRight: 15 }}
                          >
                            <RestartAltIcon /> Reset
                          </span>
                        }
                      />
                    </div>
                  </SwipeableDrawer>
                </React.Fragment>
              ))}
            </span>
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <ActivityDraftListing
          ListOfDrafts={activitiesByUserDraftTable}
          TotalNoOfRows={TotalnoofDraftRows}
          NoOfRecordperPage={NoOfDraftRecordperPage}
          deleteActivityListByIdForDraft={deleteActivityListByIdForDraft}
          fetchActivityTableDraftData={fetchActivityTableDraftData}
          setNoOfDraftRecordperPage={setNoOfDraftRecordperPage}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
        <Grid className="activiPad0" container columns={{ xs: 4, md: 12 }}>
          <BacklogActivity
            activitiesByUserBacklogTable={activitiesByUserBacklogTable}
            NoOfBacklogRecordperPage={NoOfBacklogRecordperPage}
            TotalnoofBacklogRows={TotalnoofBacklogRows}
            loadMoreBacklog={loadMoreBacklog}
            classes={classes}
            toggleDrawer={toggleDrawer}
            BacklogfilterFormData={BacklogfilterFormData}
            resetBacklogFilters={resetBacklogFilters}
            backlogfilterState={backlogfilterState}
            state={state}
            onBacklogSubmitHandler={onBacklogSubmitHandler}
            setSelectedUser={setSelectedUser}
            setbacklogFilterState={setbacklogFilterState}
            autoCompleteItems={autoCompleteItems}
          />
        </Grid>
      </TabPanel>
    </div>
  );
}
const mapStateToProps = (state) => ({
  masterDataState: state.commonReducer,
});
export default withTranslation("translation", { withRef: true })(
  connect(mapStateToProps, {
    getActivityListByIdForTable,
    getActivityForm,
    activateLoader,
    deActivateLoader,
    getSubordinateList,
    getActivityListByIdForDraftTable,
    getActivityListByIdForBacklogTable,
    deleteActivityListByIdForDraftTable,
    activateErrorMessageBar,
    setFilterBy,
    getActivityCsvFile
  })(ActivityTable)
);
