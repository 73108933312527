import React from "react";
import Breadcrumb from "../../../components/breadcrumb/breadcrumb";
import DynamicForm from "f1-dynamic-form-react";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { BankaActivityForm, handleActivitySelection, initFormData, getAutoCompleteItems, validateExtraValidations } from "./banka/createActivityForm";
import {
  addDynamicData, updateDynamicData, getMasterData, uploadFileToBlob, activateSuccessMessageBar, activateErrorMessageBar,
  activateLoader, deActivateLoader, getUserBranches, setBreadcrumbs, getBDMBranchName
} from "../../../redux/actions/common";
import { validateActivity } from "../../../redux/actions/createActivityActions";
import {
  CONFIGS,
  FOUR_WHEELER,
  PUBLIC_TRANSPORT,
  SELF_OWNED_TRANSPORT,
  TWO_WHEELER,
  STATUS_ACTIVITY_DRAFTED,
  STATUS_ACTIVITY_PLANNED,
} from "../../../utils/config";
import "./createActivity.scss";
import { isPositiveNumber } from "../../../utils/validators";
import moment from "moment";
import DirectionsBusIcon from "@mui/icons-material/DirectionsBus";
import TwoWheelerIcon from "@mui/icons-material/TwoWheeler";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import { getOptionByCode } from "./helper";
import SaveAsDraftDrawer from "./saveAsDraftDrawer";
import { saveActivityOffline } from "../../../offlineManager/activityOfflineManager";
import { v4 as uuidv4 } from "uuid";
import { minusMinuteFromDate, addMinuteToDate } from "../../../utils/utils";

import { finishDayActivityCreate, getsubordinatedetailsbylevel, getSubordinateList } from "../../../redux/actions";
import { getActivityDetails } from '../../../redux/actions/activityDetailsAction';
import { BankaSalesMangementActvityForm, handleSmaActivitySelection } from "../salesManagement/createSalesManagementActivityForm";

const customValidators = [
  {
    key: "ExpectedLead",
    validator: isPositiveNumber, // function to be called
    message: "Expected leads should be greater than 0", // message to show
  },
  {
    key: "ExpectedConversion",
    validator: isPositiveNumber, // function to be called
    message: "Expected conversion should be greater than 0", // message to show
  },
  {
    key: "TicketSize",
    validator: isPositiveNumber, // function to be called
    message: "Ticket size should be greater than 0", // message to show
  },
  {
    key: "ExpenseAmount",
    validator: isPositiveNumber, // function to be called
    message: "Expense amount should be greater than 0", // message to show
  },
  {
    key: "BillNumber",
    validator: isPositiveNumber, // function to be called
    message: "Bill number should be greater than 0", // message to show
  },
  {
    key: "TravelExpene",
    validator: isPositiveNumber, // function to be called
    message: "Travel expense should be greater than 0", // message to show
  },
];

const createActivityTitle = "Create Activity";
const activityListing = "/app/activity/all";

const createSmaActivityTitle = "Create Sales Management Activity";
const activityListingSma = '/app/activity/smaActivities';

const selectOption = [{ "key": "Select", "label": "Select", "value": "" }]
const _formatDate = "YYYY-MM-DDTHH:mm";
class ResumeDraftedActivity extends React.Component {
  constructor(props) {
    super();
    this.dyFormRef = React.createRef();
    this.state = {
      isSMARoute: props.location.pathname.includes("smaActivities"),
      current: {
        id: 1,
        MeetingEndDateTime: '',
        IsClaimRequired: false,
        BranchIdlabel: "asdasd",
      },
      formData: props.location.pathname.includes("smaActivities") ? BankaSalesMangementActvityForm : BankaActivityForm,
      autoCompleteItems: {
        BranchId: [],
      },
      uploadedFile: "",
      originalFileName: "",
      extErrors: {},
      ExpectedConversion: "",
      ExpectedLead: "",
      isActiveMobileDraft: false,

      isFormSUbmitDisabled: false,
      responseMessage: "",
    };
  }

  loadBreadCrumbs = () => {
    const breadcrumbs = [
      {
        name: this.state.isSMARoute ? "Sales Management" : "Activity",
        url: this.state.isSMARoute ? activityListingSma : activityListing,
      },
      {
        name: this.state.isSMARoute
          ? createSmaActivityTitle
          : createActivityTitle,
        url: this.state.isSMARoute
          ? "/app/salesmanagement/create"
          : "/app/activity/create",
      },
    ];
    this.props.setBreadcrumbs(breadcrumbs);
  };

  isValidBusinessActivity = async (formData) => {
    if (
      formData.hasOwnProperty("MeetingStartDateTime") &&
      formData.hasOwnProperty("MeetingEndDateTime")
    ) {
      const checkActivity = await this.props.validateActivity({
        user_id: this.props.userState.profileInfo.userId,
        formData: formData,
      });
      if (
        checkActivity.status === 200 &&
        checkActivity.data.Responsestatus === true
      ) {
        return true;
      } else {
        this.setState({
          responseMessage: checkActivity.data.Responsemessage,
        });
      }
      return false;
    }
    return true;
  };

  reset = (model) => {
    for (const [key] of Object.entries(model)) {
      model[key] = "";
    }
    this.setState({ uploadedFile: "", originalFileName: "", extErrors: {} });
    this.dyFormRef.reset(model);
  };

  onSubmitHandler = async (model) => {
    if (
      !model.isValidForm ||
      Object.keys(this.state.extErrors).length > 0 ||
      Object.keys(model.errors).length > 0
    ) {
      return false;
    }
    if(model['ExpectedConversion'] !== undefined &&
     model['ExpectedConversion'] !== '' &&
     model["ExpectedLead"]!== undefined &&
     model["ExpectedLead"]!== ''){
      const extErrors = validateExtraValidations(model);
      this.props.activateErrorMessageBar(extErrors.ExpectedConversion);
      return false;   
    }
    const formData = { ...model };
    delete formData["advanceLayoutFields"];
    delete formData["collapseStates"];
    delete formData["errors"];
    delete formData["isValidForm"];
    delete formData["id"];
    delete formData["BranchIdlabel"];
    formData["IsBacklog"] = false;
    if (formData.hasOwnProperty("MeetingStartDateTime")) {
      formData["MeetingStartDateTime"] = formData[
        "MeetingStartDateTime"
      ].replace("T", " ");
    }
    if (formData.hasOwnProperty("MeetingEndDateTime")) {
      formData["MeetingEndDateTime"] = minusMinuteFromDate(
        formData["MeetingEndDateTime"]
      ).replace("T", " ");
    }
    if (formData.hasOwnProperty("UploadBill")) {
      formData["UploadBill"] = this.state.uploadedFile;
      formData["BillFileName"] = this.state.originalFileName;
    }

    const statusOption = getOptionByCode(
      STATUS_ACTIVITY_PLANNED,
      this.props.masterDataState.ActivityStatus
    );
    formData["ActivityStatusId"] = statusOption.value;
    formData["ActivityStatus"] = statusOption.label;
    formData["UserId"] = this.props.userState.profileInfo.userId;
    // formData["AssignedTo"] = formData['BDMName'] ? formData['BDMName'] : this.props.userState.profileInfo.userId;
    formData["AssignedTo"] = formData['BDMName'].trimStart() ? formData['BDMName'].trimStart() : this.props.userState.profileInfo.userId;
    formData["CreatedDate"] = moment().format("YYYY-MM-DD HH:mm:ss");
    formData["ModifiedDate"] = moment().format("YYYY-MM-DD HH:mm:ss");
    formData["LmsleadGuid"] = uuidv4();
    formData["BankRegionName"] = formData?.bankRegion ? formData.bankRegion : null;
    formData["LmsleadlastSyncTime"] = new Date()
      .toISOString()
      .replace("T", " ")
      .replace("Z", "");
    formData["IsLmsSync"] = navigator.onLine;
    formData["CreatedBy"] = this.props.userState.profileInfo.userId;
    formData["TrainingTypeID"] = formData.typeOfTraning ? formData.typeOfTraning : null;
    // validate the activity from server side
    if (navigator.onLine) {
      this.props.activateLoader();
      const isValidActivity = await this.isValidBusinessActivity(formData);
      if (isValidActivity) {
        const result = await this.props.updateDynamicData({
          data: [formData],
          formName: CONFIGS.UPDATE_ACTIVITY_FORM,
          formBuilder: "",
          userID: this.props.userState.profileInfo.userId,
        });
        if (result.status === 200 && result.data.Responsestatus === true) {
          this.props.activateSuccessMessageBar(
            "Activity has been created successfully."
          );
          this.props.deActivateLoader();
          const saveFormData = { ...formData };
          //  SAVE activity in indexDb online
          saveFormData["TrnActivityID"] = result.data.Transactionid;
          saveActivityOffline(saveFormData).then((res) => {
            if (res) {
              this.reset(formData);
              if (this.state.isSMARoute) {
                this.props.history.push(`/app/salesmanagement/detail/${result.data.Transactionid}`)
              } else {
                this.props.history.push(`/app/activity/detail/${result.data.Transactionid}`)
              }
            }
          });
        } else {
          this.props.deActivateLoader();
          this.props.activateSuccessMessageBar(
            "Something went wrong! please try again later."
          );
        }
      } else {
        this.props.activateErrorMessageBar(this.state.responseMessage);
        this.props.deActivateLoader();
      }
    } else {
      //  SAVE activity in indexDb offline
      saveActivityOffline(formData).then((res) => {
        if (res) {
          const {
            data: { key },
          } = res;
          this.props.history.push(`/app/activity/detail/${key}`);
        }
      });
    }
  };

  handleSaveAsDraft = async (e) => {
    e.preventDefault();
    this.props.activateLoader();
    const formData = { ...this.dyFormRef.state };
    delete formData["advanceLayoutFields"];
    delete formData["collapseStates"];
    delete formData["errors"];
    delete formData["isValidForm"];
    delete formData["id"];
    delete formData["BranchIdlabel"];
    formData["IsBacklog"] = false;
    if (formData.hasOwnProperty("MeetingStartDateTime")) {
      formData["MeetingStartDateTime"] = formData[
        "MeetingStartDateTime"
      ].replace("T", " ");
    }
    if (formData.hasOwnProperty("MeetingEndDateTime")) {
      formData["MeetingEndDateTime"] = minusMinuteFromDate(
        formData["MeetingEndDateTime"]
      ).replace("T", " ");
    }
    if (formData.hasOwnProperty("UploadBill")) {
      formData["UploadBill"] = this.state.uploadedFile;
      formData["BillFileName"] = this.state.originalFileName;
    }
    const statusOption = getOptionByCode(
      STATUS_ACTIVITY_DRAFTED,
      this.props.masterDataState.ActivityStatus
    );
    formData["ActivityStatusId"] = statusOption.value;
    formData["UserId"] = this.props.userState.profileInfo.userId;
    formData["AssignedTo"] = formData['BDMName'] ? formData['BDMName'] : this.props.userState.profileInfo.userId;
    formData["CreatedDate"] = new Date()
      .toISOString()
      .replace("T", " ")
      .replace("Z", "");
    formData["ModifiedDate"] = new Date()
      .toISOString()
      .replace("T", " ")
      .replace("Z", "");
    formData["BankRegionName"] = formData?.bankRegion ? formData.bankRegion : null;
    formData["TrainingTypeID"] = formData.typeOfTraning ? formData.typeOfTraning : null;
    //formData["IsClaimRequired"] = formData["IsClaimRequired"] ? "Yes" : "No"

    const result = await this.props.updateDynamicData({
      data: [formData],
      formName: CONFIGS.UPDATE_ACTIVITY_FORM,
      formBuilder: "",
      userID: this.props.userState.profileInfo.userId,
    });
    if (result.status === 200 && result.data.Responsestatus === true) {
      this.props.activateSuccessMessageBar(
        "Activity has been drafted successfully."
      );
      this.reset(formData);
      this.props.deActivateLoader();
      this.props.history.push(this.state.isSMARoute ? activityListingSma : activityListing);
    } else {
      this.props.deActivateLoader();
      this.props.activateSuccessMessageBar(
        "Something went wrong! please try again later."
      );
    }
  };

  getActivityDraftData = async () => {
     this.props.activateLoader();
    const activityId = this.props.match.params.id;
    const result = await this.props.getActivityDetails({
      activityId: activityId,
    });
    if (result.status === 200) {
      console.log("data", result.data)
      const data = result.data;
      let selectedFormData = this.state.formData;
      const defaultState = data;
      if (data?.ActivityID) {
        const activityFormData = handleActivitySelection(
          { target: { value: data.ActivityID.toString() } },
          "ActivityID",
          selectedFormData,
          this.props.masterDataState,
          this.props.userState.profileInfo.roleId
        );

        const smaActivityFormData = handleSmaActivitySelection(
          { target: { value: data.ActivityID.toString() } },
          "SalesMangementID",
          selectedFormData,
          this.props.masterDataState,
          this.state.BDMList,
          this.state.trainingType,
          this.state.sortBankRegion,
          this.state.abhName,
          this.state.bdmByRBHName,
          this.state.zoneName,
        );

        const formData3 = this.state.isSMARoute
          ? smaActivityFormData
          : activityFormData;
        selectedFormData = [...formData3];
        defaultState.ActivityID = data.ActivityID.toString();
        defaultState[
          this.state.isSMARoute ? "SalesMangementID" : "ActivityID"
        ] = data.ActivityID.toString();
      }
      if (this.state.isSMARoute) {
        defaultState["BDMName"] = data.BDMID?.toString();
        defaultState["ABHID"] = this.props.userState.profileInfo.roleId === "ABH" ? data.ABHName : data.ABHID;
        defaultState["BranchName"] = data.BranchId?.toString();
        defaultState["Month_Year"] = data.Month_Year;
        defaultState["typeOfTraning"] = data?.TrainingTypeID?.toString();
        defaultState["RBHName"] = data.RBHName ? data.RBHName : null;

        defaultState["bankRegion"] = data?.BankRegionName;
        defaultState["SMAZoneID"] = data?.SMAZoneID;
        defaultState["MeetingStartDateTime"] = addMinuteToDate(defaultState["MeetingStartDateTime"]);
        defaultState["MeetingEndDateTime"] = addMinuteToDate(defaultState["MeetingEndDateTime"]);
      } else {
        if (data?.BranchId) {
          defaultState.BranchId = data.BranchId.toString();
          defaultState.BranchName = data.BranchName;
          defaultState.BranchIdlabel = data.BranchName;
        }
        if (data?.FpmMeetingTypeID) {
          defaultState.FpmMeetingTypeID = data.FpmMeetingTypeID.toString();
        }
        if (data?.SRMID) {
          defaultState.SRMID = data.SRMID.toString();
        }
        if (data?.ActivitySubTypeId) {
          const formData1 = handleActivitySelection(
            { target: { value: data.ActivitySubTypeId.toString() } },
            "ActivitySubTypeId",
            selectedFormData,
            this.props.masterDataState,
            this.props.userState.profileInfo.roleId
          );
          selectedFormData = [...formData1];
          defaultState.ActivitySubTypeId = data.ActivitySubTypeId.toString();
        }
        if (
          data?.IsClaimRequired === ("True" || true) ||
          data?.IsClaimRequired === ("False" || false)
        ) {
          const flag = data?.IsClaimRequired === ("True" || true) ? true : false;
          const formData2 = handleActivitySelection(
            { target: { value: flag } },
            "IsClaimRequired",
            selectedFormData,
            this.props.masterDataState,
            this.props.userState.profileInfo.roleId
          );
          selectedFormData = [...formData2];
        }
      }
      const formData = selectedFormData.map((option) => {
        if (option.key === "MeetingStartDateTime") {
          option.min = moment(new Date()).format("YYYY-MM-DDTHH:mm");
          return option
        } else {
          return option
        }
      })

      defaultState["MeetingEndDateTime"] = addMinuteToDate(defaultState["MeetingEndDateTime"])
      this.setState({ formData: formData, current: defaultState })
      this.props.deActivateLoader()

      if (this.state.current.SalesMangementID === "27") {
        if (this.state.current.BDMName && this.state.current.BDMName !== '') {
          const res = await this.props.getBDMBranchName(this.state.current.BDMName)
          if (res.status === 200) {
            if (this.state.formData[0].options[2].value === "27") {
              const copyFormData = this.state.formData;
              copyFormData[2].options = this.getOptionBdmList(res.data);
              // this.State({formData:copyFormData});
              this.setState({
                formData: copyFormData
              });
            }
          }
        }
      }
    }
    // if (this.state.current.SalesMangementID === "27") {
    //   const BranchId = this.state.current.BranchId
    //   if (this.state.current.BDMName && this.state.current.BDMName !== '') {
    //    const  res = await this.props.getBDMBranchName(this.state.current.BDMName)
    //    console.log("event working res",res)
    //    if (res.status === 200) {
    //      if (this.state.formData[0].options[2].value === "27") {
    //        const copyFormData = this.state.formData;
    //        copyFormData[2].options = this.getOptionBdmList(res.data);
    //        // this.State({formData:copyFormData});
    //        this.setState({
    //          current: {
    //            ...this.state.current,
    //            BDMName:BranchId
    //          },
    //          formData: copyFormData
    //        });
    //      }
    //    }
    //  }}

  }

  componentDidMount = async () => {
    this.props.activateLoader();
    const res = await this.props.getsubordinatedetailsbylevel({ userId: this.props.userState.profileInfo.userId, level: "ABH" })
    const resBDM = await this.props.getsubordinatedetailsbylevel({ userId: this.props.userState.profileInfo.userId, level: "BDM" })
    this.props.deActivateLoader()
    // console.log(res.data);
    // setBDMByRBHName(resBDM.data)
    
    const abhUserId = this.props.userState.profileInfo.userId
    const BDMList = this.props.leadFormReducer.BDMList.UsersDetails;
    const trainingType = this.props.masterDataState.smaTrainingType;
    const bankRegion = (this.props.userState.profileInfo.roleId == "RBH" && this.props.userState.profileInfo.channelid==1)?this.props.masterDataState.smaRBHBankRegion :this.props.masterDataState.smaBankRegion;
    const sortBankRegion = bankRegion.filter(
      (item) => item.ABHUserID == abhUserId
    );
    const zoneData=this.props.masterDataState.zoneName;
    this.loadBreadCrumbs();
    const formData = this.props.masterDataState ? initFormData(this.state.formData, this.props.masterDataState, this.props.userState.profileInfo.roleId, this.props.userState.profileInfo.channelid, this.state.isSMARoute) : this.state.formData;
    const autoCompleteItems = { ...this.state.autoCompleteItems };

    autoCompleteItems.BranchId = this.props.masterDataState ? getAutoCompleteItems(this.props.masterDataState, CONFIGS.MASTER_DATA_TABLES.BRANCHES.TABLE_NAME) : []
    this.setState({
      formData: formData,
      autoCompleteItems: autoCompleteItems,
      BDMList: this.getOptionData(BDMList),
      trainingType: this.getOptionTrainingType(trainingType),
      sortBankRegion: this.getOptionBankRegion(sortBankRegion),     
      abhName: this.props.userState.profileInfo.roleId == "RBH" ? this.getRBHOptionData(res.data) : [],
      bdmByRBHName: this.props.userState.profileInfo.roleId == "RBH" && this.props.userState.profileInfo.channelid!=1 ? this.getBDMBYRBHOptionData(resBDM.data) : this.getBDMBYRBHForSMOptionData,
      zoneName:this.getOptionZoneName(zoneData),
      current: {
        ...this.state.current,
      }
    });
    this.getActivityDraftData();
  }
  getOptionBdmList = (optionList) => {
    if (optionList) {
      const options = optionList?.map((item) => {
        return {
          key: item["BranchID"],
          label: `${item["BranchName"]} - ${item["BranchCode"]} `,
          value: item["BranchID"],
        };
      });
      return [...selectOption, ...options];
    }
  };
  getOptionData = (optionList) => {
    if (optionList) {
      const options = optionList?.map((item) => {
        return {
          key: item["UserID"],
          label: item["UserName"],
          value: item["UserID"],
        };
      });
      return [...selectOption, ...options];
    }
  };

  getOptionTrainingType = (optionList) => {
    if (optionList) {
      const options = optionList?.map((item) => {
        return {
          key: item["ID"],
          label: item["TrainingType"],
          value: item["ID"],
        };
      });
      return [...selectOption, ...options];
    }
  };
   getOptionZoneName = (optionList) => {
    const options = optionList.map((item) => {
      return {
        key: item["SMABankZoneID"],
        label: `${item["Name"]}`,
        value: `${item["Name"]}`,
      };
    });
    return [...selectOption, ...options];
  };
  getOptionBankRegion = (optionList) => {
    if (optionList) {
      const options = optionList?.map((item) => {
        return {
          key: item["ABHUserID"],
          label: `${item["RegionName"]} - ${item["RHName"]} `,
          value: `${item["RegionName"]} - ${item["RHName"]} `,
        };
      });
      return [...selectOption, ...options];
    }
  };

  getRBHOptionData = (optionList) => {
    if (optionList) {
      const options = optionList?.map((item) => {
        return {
          key: item["userID"],
          label: item["userName"],
          value: item["userID"],
        };
      });
      return [...selectOption, ...options];
    }else{
      return [];
    }
  };

  getBDMBYRBHOptionData = (optionList) => {
    if (optionList) {
      const options = optionList?.map((item) => {
        return {
          key: item["Name"],
          label: item["Name"],
          value: item["UserID"],
        };
      });
      return [...selectOption, ...options];
    }
  };

  getBDMBYRBHForSMOptionData= (optionList) => {
    if (optionList) {
      const options = optionList?.map((item) => {
        return {
          key: item["userName"],
          label: item["userName"],
          value: item["userID"],
        };
      });
      return [...selectOption, ...options];
    }
  };

  getOptionBdmList = (optionList) => {
    const options = optionList.map((item) => {
      return {
        key: item["BranchID"],
        label: `${item["BranchName"]} - ${item["BranchCode"]} `,
        value: item["BranchID"],
      };
    });
    return [...selectOption, ...options];
  }
  uploadFile = async (files) => {
    const file = files[0]
    if (file) {
      const fileName = new Date().getTime() + file.name;
      const fileRes = await this.props.uploadFileToBlob({ 'file': file, 'fileName': fileName });
      if (fileRes.status === 200) {
        this.setState({ uploadedFile: fileName, originalFileName: file.name })
      }
    }
  };

  checkFinishDayActivity = async (value) => {
    if (value === "6") {
      try {
        const result = await this.props.finishDayActivityCreate(
          this.props.userState.profileInfo.userId
        );
        if (result.status === 200) {
          this.setState({ isFormSUbmitDisabled: false });
        }
      } catch (e) {
        this.setState({ isFormSUbmitDisabled: true });
      }
    } else {
      this.setState({ isFormSUbmitDisabled: false });
    }
  }

  onChangeHandler = async (event, key, model) => {
    if (this.state.isSMARoute) {
      const smaActivityFormData = handleSmaActivitySelection(
        event,
        key,
        this.state.formData,
        this.props.masterDataState,
        this.state.BDMList,
        this.state.trainingType,
        this.state.sortBankRegion,
        this.state.abhName,
        this.state.bdmByRBHName,
        this.state.zoneName,
      );
      this.setState({ formData: [...smaActivityFormData] });

      if (key === "SalesMangementID") {
        this.setState({
          current: {
            ...this.state.current,
            SalesMangementID: event.target.value,
            MeetingStartDateTime: moment(new Date()).format(_formatDate),
            MeetingEndDateTime: moment(new Date()).add(30, "m").format(_formatDate),
            min: moment(new Date()).format(_formatDate),
            max: moment(new Date()).add(30, "m").format(_formatDate),
          }
        });
      }
      if (this.state.current.SalesMangementID === "27") {
        if (key === "BranchName") {
          this.setState({
            current: {
              ...this.state.current,
              BranchId: event.target.value,
              BranchName: event.target.value
            }
          });
        }
        if (key === "BDMName") {
          this.setState({
            current: {
              ...this.state.current,
              BDMName: event.target.value
            }
          });
          model.BranchName = "";
          //const BranchId = event.target.value
          if (event.target.value && event.target.value !== '') {
            const res = await this.props.getBDMBranchName(event.target.value)
            if (res.status === 200) {
              if (this.state.formData[0].options[2].value === "27") {
                const copyFormData = this.state.formData;
                copyFormData[2].options = this.getOptionBdmList(res.data);
                // this.State({formData:copyFormData});
                this.setState({
                  formData: copyFormData
                });
              }
            }
          }
        }

      }
      //RBH Sales management Changes for showing dropdownvalues
      // if (this.state.current.SalesMangementID === "75") {
      //   if (key === "BranchName") {
      //     this.setState({
      //       current: {
      //         ...this.state.current,
      //         BranchId: event.target.value,
      //         BranchName: event.target.value
      //       }
      //     });
      //   }
      //   if (key === "BDMName") {
      //     this.setState({
      //       current: {
      //         ...this.state.current,
      //         BDMName: event.target.value
      //       }
      //     });
      //     model.BranchName = "";
      //     //const BranchId = event.target.value
      //     if (event.target.value && event.target.value !== '') {
      //       const res = await this.props.getBDMBranchName(event.target.value)
      //       if (res.status === 200) {
      //         if (this.state.formData[0].options[2].value === "27") {
      //           const copyFormData = this.state.formData;
      //           copyFormData[2].options = this.getOptionBdmList(res.data);
      //           // this.State({formData:copyFormData});
      //           this.setState({
      //             formData: copyFormData
      //           });
      //         }
      //       }
      //     }
      //   }

      // }

      if (this.state.current.SalesMangementID === "75" || this.state.current.SalesMangementID === "78") {
        if (key === "BDMName") {
          model.BranchName = "";
          if (event.target.value && event.target.value !== '') {
            const res = await this.props.getBDMBranchName(event.target.value)
           if (res.status === 200) {
              if (this.state.formData[0].options[1].value === "75") {
                this.props.deActivateLoader()
                const copyFormData = this.state.formData;
                copyFormData[3].options = this.getOptionBdmList(res.data);
                //setBranchList(this.getOptionBdmList(res.data));
                this.setState({
                  formData: copyFormData
                });
              }
            }
          }
        }
        if (key === "ABH_BDM") {
        let res;
          if (event.target.value && event.target.value !== '') {
            if(event.target.value=="ABH"){
               res = await this.props.getsubordinatedetailsbylevel({userId: this.props.userState.profileInfo.userId, level: "ABH"})
            }else if(event.target.value=="BDM"){
               res = await this.props.getsubordinatedetailsbylevel({userId: this.props.userState.profileInfo.userId, level: "BDM"})
            }else if(event.target.value=="ABH/BDM"){
              res = await this.props.getsubordinatedetailsbylevel({userId: this.props.userState.profileInfo.userId, level: "ABH_BDM"})
           }
            
             if (res.status === 200) {
              if (this.state.formData[0].options[1].value === "75") {
                this.props.deActivateLoader();
                const copyFormData = this.state.formData;
                copyFormData[2].options = this.getBDMBYRBHForSMOptionData(res.data);
                // setBranchList(getOptionBdmList(res.data));
                this.setState({
                  formData: copyFormData
                });
              }
            }
          }
        }
      }

      if (this.state.current.SalesMangementID === "80") {
        if (key === "Team_BDM") {
          if (event.target.value=="BDM") {
            if (this.state.formData[0].options[6].value === "80") {
              let res = await this.props.getsubordinatedetailsbylevel({userId: this.props.userState.profileInfo.userId, level: "BDM"})
              this.props.deActivateLoader()
              const copyFormData = this.state.formData;;
              copyFormData[2].hide = false;
              copyFormData[2].options = this.getBDMBYRBHForSMOptionData(res.data);
              // setBranchList(getOptionBdmList(res.data));
              this.setState({
                formData: copyFormData
              });
            }
          }else{
            const copyFormData = this.state.formData;;
            copyFormData[2].hide = true;
            this.setState({
              formData: copyFormData
            });
          }
        }
      }
}
    if (key === 'UploadBill') {
      if (navigator.onLine) {
        this.uploadFile(event);
      } else {
        this.props.activateErrorMessageBar(
          "Cannot upload files in offline mode!"
        );
      }
      if (key === "MeetingStartDateTime") {
        let StartDate = moment(event.target.value).format(_formatDate);
        this.state.formData.map((option) => {
          if (option.key === "MeetingEndDateTime") {
            option.max = moment(StartDate).endOf("day").format("YYYY-MM-DD kk:mm");
            option.min = StartDate;
          }
          this.setState({
            current: {
              ...this.state.current,
              MeetingStartDateTime: event.target.value,
              MeetingEndDateTime: moment(new Date(event.target.value))
                .add(30, "m")
                .format(_formatDate),
            },
          });
        })
      }
      if (key === "MeetingEndDateTime") {
        this.setState({
          current: {
            ...this.state.current,
            MeetingEndDateTime: moment(event.target.value).format(_formatDate),
          },
        });
      }
      if (key === "ActivityDateTime") {
        this.setState({
          current: {
            ...this.state.current,
            ActivityDateTime: moment(event.target.value).format(_formatDate),
          },
        });
      }
    } else {
      if (key === "UploadBill") {
        if (navigator.onLine) {
          this.uploadFile(event);
        } else {
          this.props.activateErrorMessageBar(
            "Cannot upload files in offline mode!"
          );
        }
      } else if (key === "MeetingStartDateTime") {
        const form = this.state.formData.map((ele) => {
          if (ele.key == "MeetingEndDateTime") {
            ele.min = moment(event.target.value).add(1, "m").format("YYYY-MM-DDTHH:mm")
            ele.max = moment(event.target.value).endOf("day").format("YYYY-MM-DDTHH:mm")
            return ele
            //ele.MeetingEndDateTime["min"] = moment(event.target.value).add(30, "m").format("YYYY-MM-DDTHH:mm")
          } else {
            return ele
          }
        })
        const newDate = moment(event.target.value).add(30, "m").format("YYYY-MM-DDTHH:mm");
        this.setState({
          current: {
            ...this.state.current,
            MeetingStartDateTime: event.target.value,
            MeetingEndDateTime: newDate,
          },
          formData: form
        });
      } else if (key === "MeetingEndDateTime") {
        const newDate = moment(event.target.value).format("YYYY-MM-DDTHH:mm");
        this.setState({
          current: { ...this.state.current, MeetingEndDateTime: newDate },
        });
      } else if (key === "IsClaimRequired") {
        this.setState({
          current: { ...this.state.current, IsClaimRequired: event },
        });
      } else if (key === "ActivityID") {
        this.setState({
          current: {
            ...this.state.current,
            ActivityID: event.target.value,
            ActivityName: "",
          },
        });
        this.checkFinishDayActivity(event.target.value);
        // } else if (key === "BranchId") {
        //   // Alert -if this empty else if removed automcomplete will not work
        //   this.setState({
        //     current: { ...this.state.current, [key]: event.target.value },
        //   });
      } else {
        this.setState({
          current: { ...this.state.current, [key]: event.target.value },
        });
      }

      if (
        key === "ActivityID" ||
        key === "IsClaimRequired" ||
        key === "TransportModeID"
      ) {
        const formData = handleActivitySelection(
          event,
          key,
          this.state.formData,
          this.props.masterDataState,
          this.props.userState.profileInfo.roleId
        );
        this.setState({ formData: formData });
      }
      // else {this.setState({current: { ...this.state.current, [key]: event.target.value}})}

      if (key === "ExpectedLead" || key === "ExpectedConversion") {
        this.setState({ [key]: event.target.value }, () => {
          const extErrors = validateExtraValidations(this.state);
          this.setState({ extErrors: extErrors });
        });
      }
    }


  }

  onAutocompleteSelectHandler = (key, value, item) => {
    //this.setState({current:{...this.state.current,[key]:value},[key]:value})
  }

  onCancelHandler = () => {
    const isMobile = Math.min(window.screen.width, window.screen.height) < 768 || navigator.userAgent.indexOf("Mobi") > -1;
    if (isMobile) {
      this.setState({ isActiveMobileDraft: true })
    }
    else {
      this.props.history.push(this.state.isSMARoute ? activityListingSma : activityListing)
    }
  }

  removeFile = (e, file, index, key) => {
    this.dyFormRef.removeFile(e, file, index, key);
    this.setState({ uploadedFile: "" });
  };

  renderUploadedFilesItem = (file, index, key) => {
    return (
      <li style={{ borderColor: "#ccc" }} key={index}>
        <span className="file-name">{file.name}</span>
        <span
          className="action"
          onClick={(e) => this.removeFile(e, file, index, key)}
        >
          <i className="fa fa-trash"></i>
        </span>
      </li>
    );
  };

  renderRadioIcons = (radioOption) => {
    let icon = "";
    if (radioOption.key === PUBLIC_TRANSPORT) {
      icon = <DirectionsBusIcon></DirectionsBusIcon>;
    } else if (radioOption.key === SELF_OWNED_TRANSPORT) {
      icon = <TwoWheelerIcon></TwoWheelerIcon>;
    } else if (radioOption.key === TWO_WHEELER) {
      icon = <TwoWheelerIcon></TwoWheelerIcon>;
    } else if (radioOption.key === FOUR_WHEELER) {
      icon = <DirectionsCarIcon></DirectionsCarIcon>;
    }
    return icon;
  };

  toggleDrawer = (flag) => {
    this.setState({ isActiveMobileDraft: flag });
  };

  onDontSaveDraftHandler = () => {
    this.props.history.push(this.state.isSMARoute ? activityListingSma : activityListing);
  };

  onSaveDraftHandler = () => {
    this.handleSaveAsDraft(new Event("test"));
  };

  render() {
    const { t } = this.props;
    return <div className="mainContainer createActivity">
      <Breadcrumb></Breadcrumb>
      {/* <h3 className="pageHead">{createActivityTitle}</h3> */}
      <span className="formInfo">{t('Please fill in the activity information')}</span>
      <div className="formBox">
        <DynamicForm
          key={this.state.current.id}
          onRef={ref => this.dyFormRef = ref}
          title={t(createActivityTitle)}
          buttonSubmit={t('Submit')}
          defaultValues={this.state.current}
          model={this.state.formData}
          onSubmit={(model) => {
            this.onSubmitHandler(model);
          }}
          disableSubmitOnInValid={this.state.isFormSUbmitDisabled}
          design="bootrstap"
          materialOutline={false}
          onChange={(event, key, model) => this.onChangeHandler(event, key, model)}
          buttonNormalClick={(id, key, obj) => {
          }}
          onAutocompleteSelect={this.onAutocompleteSelectHandler}
          autoCompleteItems={this.state.autoCompleteItems}
          showAstersikOnRequiredFields={true}
          extErrors={this.state.extErrors}
          className="activityTypeDiv"
          extaraActionFormButton={<>
            <a className="savedraftLink" onClick={(e) => this.handleSaveAsDraft(e)} href="#">SAVE AS DRAFT</a>
            <button className="btn cancelBtn" key="cancel" id="cancel" type="button" onClick={this.onCancelHandler}>{t('Cancel')}</button>
          </>}
          fileUploaderFields={['UploadBill']}
          renderUploadedFilesItem={this.renderUploadedFilesItem}
          customValidators={customValidators}
          renderRadioIcons={(option) => this.renderRadioIcons(option)}
        />
      </div>
      <SaveAsDraftDrawer
        isActiveMobileDraft={this.state.isActiveMobileDraft}
        toggleDrawer={this.toggleDrawer}
        onDontSaveHandler={this.onDontSaveDraftHandler}
        onSaveDraftHandler={this.onSaveDraftHandler}
      ></SaveAsDraftDrawer>
    </div>
  }
}

const mapStateToProps = state => ({
  leadFormReducer: state.leadFormReducer,
  masterDataState: state.commonReducer,
  userState: state.userReducer,
});
const mapDispatchToProps = (dispatch) => ({
  getSubordinateList: (payload) => dispatch(getSubordinateList(payload)),
  getsubordinatedetailsbylevel: (payload) => dispatch(getsubordinatedetailsbylevel(payload)),
  addDynamicData: (payload) => dispatch(addDynamicData(payload)),
  updateDynamicData: (payload) => dispatch(updateDynamicData(payload)),
  getMasterData: (payload) => dispatch(getMasterData(payload)),
  uploadFileToBlob: (payload) => dispatch(uploadFileToBlob(payload)),
  validateActivity: (payload) => dispatch(validateActivity(payload)),
  activateSuccessMessageBar: (payload) =>
    dispatch(activateSuccessMessageBar(payload)),
  activateErrorMessageBar: (payload) =>
    dispatch(activateErrorMessageBar(payload)),
  activateLoader: (payload) => dispatch(activateLoader(payload)),
  deActivateLoader: (payload) => dispatch(deActivateLoader(payload)),
  getUserBranches: (payload) => dispatch(getUserBranches(payload)),
  getActivityDetails: (payload) => dispatch(getActivityDetails(payload)),
  setBreadcrumbs: (payload) => dispatch(setBreadcrumbs(payload)),
  getBDMBranchName: (payload) => dispatch(getBDMBranchName(payload)),
  finishDayActivityCreate: (payload) =>
    dispatch(finishDayActivityCreate(payload)),
});
export default withTranslation("translation", { withRef: true })(
  connect(mapStateToProps, mapDispatchToProps)(ResumeDraftedActivity)
);
